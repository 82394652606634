@import "../uikit/mixins";

/* -----------------------------  Fonts ----------------------------- */

// Roboto

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto/Roboto-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto/Roboto-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto/Roboto-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Cormorant

@font-face {
  font-family: "Cormorant";
  src: url("../../fonts/Cormorant/Cormorant-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cormorant";
  src: url("../../fonts/Cormorant/Cormorant-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cormorant";
  src: url("../../fonts/Cormorant/Cormorant-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Inter

@font-face {
  font-family: "Inter";
  src: url("../../fonts/Inter/Inter-Light.woff2") format("woff2"),
    url("../../fonts/Inter/Inter-Light.woff") format("woff");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../../fonts/Inter/Inter-Regular.woff2") format("woff2"),
    url("../../fonts/Inter/Inter-Regular.woff?v=3.19") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../../fonts/Inter/Inter-Medium.woff2") format("woff2"),
    url("../../fonts/Inter/Inter-Medium.woff") format("woff");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../../fonts/Inter/Inter-Bold.woff2?v=3.19") format("woff2"),
    url("../../fonts/Inter/Inter-Bold.woff?v=3.19") format("woff");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

body {
  font-family: $roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 130%;
}

h1,
.h1,
h1 * {
  @include h1;
}

h2,
.h2,
h2 * {
  @include h2;
}

h3,
.h3,
h3 * {
  @include h3;
}

h4,
.h4,
h4 * {
  @include h4;
}

h5,
.h5,
h5 * {
  @include h5;
}

.txt {
  &__body {
    @include body__text;
  }
  &__small-body {
    @include txt__small-body;
  }
  &__small-addition {
    @include txt__small-addition;
  }
  &__cormorant {
    @include txt__cormorant;
  }
  &__roboto {
    @include txt__roboto;
  }
}
