@import "./vars/vars";
@import "./mixins";

// Primary buttons
.btn-white {
  @include btn-goto(
    $color_white,
    $color_dark_grey_2,
    $color_dark_grey_2,
    $color_dark_grey_2,
    $color_white,
    $color_white
  );
}
.btn-black {
  @include btn-goto(
    $color_dark_grey_2,
    $color_white,
    $color_dark_grey_2,
    $color_white,
    $color_dark_grey_2,
    $color_dark_grey_2
  );
}

.btn {
  @include h6;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  text-overflow: ellipsis;
  transition: 0.3s color ease, 0.3s background ease;
  letter-spacing: 0.03em;
  cursor: pointer;

  * {
    font-weight: 700;
  }

  &_show {
    span {
      color: $color_dark_grey_2;
    }
    &-more {
      width: 240px;
      height: 60px;
      position: relative;
      @include hover-border-reverse;

      span {
        color: $color_dark_grey_2;
      }

      @media (max-width: $phone-max) {
        height: 50px;
      }
    }
  }
  &_wide {
    display: flex;
    width: 100%;
    max-width: 100%;
  }
  &_gold {
    background: $color_yellow;
    color: $color_black;
  }
  &_header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    padding: 6px 0px;
  }
  &_hover-opacity {
    letter-spacing: 0.03em;
    &:hover {
      opacity: 0.8;
    }
  }
  &_more {
    color: $color_brown;
    background-color: $color_bg_grey;
    padding: 22px 60px;
    border: none;
    position: relative;
    @include hover-border;
  }
  &_more_visible-borders {
    color: $color_brown;
    background-color: transparent;
    padding: 22px 60px;
    border: none;
    position: relative;
    @include hover-border-reverse;
  }
  &-goto {
    @include btn-goto(
      $color_dark_grey_2,
      $color_white,
      transparent,
      transparent,
      $color_dark_grey_2,
      $color_dark_grey_2
    );
    width: 239px;

    &-white {
      @include btn-goto(
        $color_white,
        $color_dark_grey_2,
        transparent,
        $color_dark_grey_2,
        $color_white,
        $color_white
      );
      width: 175px;
      @media (max-width: $phone_max) {
        width: 100%;
      }
    }
  }
  &_goto-with-arrow {
    color: $color_white;
    background-color: $color_grey;
    padding: 32px 60px 32px 0;
    border: none;
    position: relative;
    max-width: 441px;
    width: 100%;
    &_transparent {
      background: transparent;
      padding: 0;
      width: auto;
    }
    .arrow {
      position: relative;
      background: url("../../images/icons/long-arrow.svg") no-repeat center;
      width: 60px;
      height: 15px;
      margin-right: 35px;
      &:before {
        content: "";
        right: -8px;
        display: block;
        position: absolute;
        width: 40px;
        height: 40px;
        top: 50%;
        border-radius: 50%;
        transform: translateY(-50%);
        left: 35px;
        border-bottom: 1px solid #c8c8c8;
        transition: 0.3s transform linear;
        transition: 0.3s transform linear, 0.3s -webkit-transform linear;
      }
      &:after {
        content: "";
        right: -8px;
        display: block;
        position: absolute;
        width: 40px;
        height: 40px;
        top: 50%;
        transform: translateY(-50%);
        left: 35px;
        border-radius: 50%;
        border-top: 1px solid #c8c8c8;
        transition: 0.6s transform linear 0.3s;
      }
    }
    &:hover {
      color: $color_white;
      .arrow {
        &:before {
          content: "";
          top: 50%;
          transform: rotate(180deg) translateY(50%);
          transition: 0.3s transform linear;
        }
        &:after {
          content: "";
          top: 50%;
          transform: rotate(180deg) translateY(50%);
          transition: 0.6s transform linear 0.3s;
        }
      }
    }
  }
  // big btn open link
  &_goto-svg-arrow {
    color: #fff;
    background-color: #38373d;
    padding: 20px 60px 20px 0;
    border: none;
    position: relative;
    max-width: 441px;
    width: 100%;
    span {
      margin-left: 15px;
    }
    svg {
      circle {
        cursor: pointer;
        stroke-dasharray: 122px;
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 600ms ease;
        transform: rotate(90deg);
        transform-origin: 56px 19px;
      }
    }
    &:hover {
      color: #fff;
      svg {
        circle {
          stroke-dashoffset: 122px;
        }
      }
    }
    &_bg-transparent {
      background: transparent;
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
    &_no-txt {
      padding: 0;
      width: auto;
    }
  }
  // small btn open link
  &_open-svg-arrow {
    cursor: pointer;
    svg {
      circle {
        stroke-dasharray: 122px;
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 600ms ease;
        transform: rotate(90deg);
        transform-origin: 36px 19px;
      }
    }
    &:hover {
      svg {
        circle {
          stroke-dashoffset: 122px;
        }
      }
    }
    &_white {
      svg {
        rect {
          fill: $color_white;
        }
      }
    }
    &_with-txt {
      span {
        @include h6;
        margin-right: 15px;
        color: $color_dark_grey_2;
        letter-spacing: 0.03em;
        text-transform: uppercase;
      }
    }
  }
  &_more-svg-vb {
    color: $color_brown;
    background-color: transparent;
    padding: 22px 60px;
    border: none;
    position: relative;
    min-height: 60px;
    &_bg-grey {
      background: $color_bg_grey;
    }
    svg {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      fill: transparent;
      rect {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        fill: transparent;
        stroke: $color_brown;
        stroke-width: 2px;
        transition: stroke-dashoffset 600ms linear;
        z-index: 1;
        stroke-dasharray: 150 480;
        stroke-dashoffset: 150;
      }
    }
    &:hover {
      color: $color_brown;
      svg {
        rect {
          // stroke-dasharray: 0 100%;
          stroke-dashoffset: -480;
        }
      }
    }
  }
  &_expand {
    padding: 0;
    background: none;
    outline: none;
    span {
      position: relative;
      @include h5;
      font-size: 12px;
      color: $color_dark_gold;
      font-weight: 700;
      transition: all 0.3s ease-in;
      display: flex;
      align-items: center;
      &:after {
        content: "";
        display: block;
        width: 7px;
        height: 14px;
        background: url(../../images/icons/arrow_left_sprite.svg#arrow_left_black_gold)
          no-repeat center;
        transform: rotate(270deg);
        transition: all 0.3s ease-in;
        margin-left: 10px;
      }
    }
    &:hover {
      span {
        color: $color_grey;
      }
    }
    &.active {
      span:after {
        // content: "";
        // display: block;
        // width: 7px;
        // height: 14px;
        // background: url(../../images/icons/arrow_left_sprite.svg#arrow_left_black_gold)
        //   no-repeat center;
        transform: rotate(90deg);
      }
    }
  }
  &[disabled] {
    background: $color_white_smoke;
  }
  &-collapse {
    @include btn-text;
    @include h6;
    cursor: pointer;
    color: $color_dark_grey_2;
    margin-top: 36px;
    &.active {
      span:after {
        transform: rotate(90deg);
      }
    }
    @media all and (max-width: $phone_max) {
      margin-top: 23px;
    }
    span {
      display: flex;
      align-items: center;
      &:after {
        content: "";
        display: block;
        width: 7px;
        height: 14px;
        background: url(../../images/icons/arrow_left_sprite.svg#arrow_left_black)
          no-repeat center;
        transform: rotate(270deg);
        margin-left: 10px;
        transition: all 0.3s ease-in;
      }
    }
    &:hover {
      span {
        color: $color_grey;
      }
    }
  }
}

.btn-show {
  position: relative;
  border: none;
  background: transparent;
  padding: 0;

  &::before {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 1px;
    transform-origin: center;
    transform: scaleX(1);
    transition-property: transform, opacity;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
    opacity: 1;
    background: $color_dark_grey_2;
  }

  &:hover {
    cursor: pointer;
    &:before {
      transform: scaleX(0);
      opacity: 0;
    }
  }

  span {
    @include h6;
    letter-spacing: 0.03em;
    color: $color_dark_grey_2;
  }
}

.btn-goto-buy {
  @include flex-center;

  width: 239px;
  height: 60px;
  background-color: $color_dark_grey_2;
  border: 1px solid $color_dark_grey_2;

  span {
    @include h6;
    letter-spacing: 0.03em;
    color: $color_white;
  }

  &:hover {
    background-color: $color_white;

    span {
      color: $color_dark_grey_2;
    }
  }
}

.add-to-cart-btn {
  text-transform: uppercase;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s linear;
  &__icon {
    display: inline-flex;
    width: 22px;
    height: 22px;
    background: url("../../images/icons/cart.svg") no-repeat center;
    background-size: contain;
    position: relative;
    margin-right: 10px;
    transition: border-radius 0.3s linear;
  }
  &:hover {
    color: $color_brown;
    .add-to-cart-btn__icon {
      display: inline-flex;
      width: 22px;
      height: 22px;
      background: $color_brown;
      background-size: contain;
      background-size: 11px;
      position: relative;
      border-radius: 50%;
      transition: border-radius 0.3s linear;
      &:before {
        content: "";
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 10px;
        background: url("../../images/icons/cart_white.svg") no-repeat center;
        background-size: contain;
        z-index: 1;
      }
    }
  }
}

.add-to-favorites-btn {
  text-transform: uppercase;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s linear;
  &__icon {
    display: inline-flex;
    width: 22px;
    height: 22px;
    background: url("../../images/icons/wishlist.svg") no-repeat center center;
    background-size: contain;
    position: relative;
    margin-right: 10px;
    transition: border-radius 0.3s linear;
  }
  &:hover {
    color: $color_brown;
    .add-to-favorites-btn__icon {
      display: inline-flex;
      position: relative;
      width: 22px;
      height: 22px;
      background: $color_brown;
      background-size: contain;
      background-size: 11px;
      position: relative;
      border-radius: 50%;
      transition: border-radius 0.3s linear;
      &:before {
        content: "";
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 8px;
        background: url("../../images/icons/wishlist_conture_white.svg")
          no-repeat center center;
        background-size: contain;
        z-index: 1;
      }
    }
  }
}

.link {
  text-transform: uppercase;
  position: relative;
  cursor: pointer;

  &_underline-to-center {
    @include h6;
    letter-spacing: 0.03em;
    color: $color_white;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -5px;
      background: $color_white;
      width: 100%;
      height: 1px;
      transform-origin: center;
      transform: scaleX(1);
      transition-property: transform, opacity;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
      opacity: 1;
    }
    &:hover {
      color: $color_white;

      &:before {
        transform: scaleX(0);
        opacity: 0;
      }
    }
    &_black {
      color: $color_black;
      &:before {
        background: $color_black;
      }
      &:hover {
        color: $color_black;
      }
    }
    &_grey {
      color: $color_grey;
      &:before {
        background: $color_grey;
      }
      &:hover {
        color: $color_grey;
      }
    }
    &_dark-grey {
      color: $color_dark_grey_2;
      &:before {
        background: $color_dark_grey_2;
      }
      &:hover {
        color: $color_dark_grey_2;
      }
    }
    &_gold {
      color: $color_gold;
      &:before {
        background: $color_gold;
      }
      &:hover {
        color: $color_gold;
      }
    }
    &_dark-gold {
      color: $color_dark_gold;
      &:before {
        background: $color_dark_gold;
      }
      &:hover {
        color: $color_dark_gold;
      }
    }
    &_brown {
      color: $color_brown;
      &:before {
        background: $color_brown;
      }
      &:hover {
        color: $color_brown;
      }
    }
  }
  &_underline-from-center {
    color: $color_white;
    font-family: $roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 130%;
    transform: perspective(1px) translateZ(0);
    position: relative;
    overflow: hidden;
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      left: 51%;
      right: 51%;
      bottom: -2px;
      background: $color_white;
      height: 1px;
      transition-property: left, right;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
    }
    &:hover {
      color: $color_white;
      &:before {
        content: "";
        background: $color_white;
        left: 0;
        right: 0;
      }
    }
  }
}

.small-arrow-open {
  display: flex;
  width: 57px;
  height: 40px;
  background: url(../../images/icons/small_arrow_open.svg) no-repeat center;
  position: relative;
  &_white {
    background: url(../../images/icons/small_arrow_open_white.svg) no-repeat
      center;
  }
  &:before {
    content: "";
    right: -8px;
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border-bottom: 1px solid $color_dark_grey;
    transition: 0.3s transform linear;
  }
  &:after {
    content: "";
    right: -8px;
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border-top: 1px solid $color_dark_grey;
    transition: 0.6s transform linear 0.3s;
  }
  &:hover {
    &:before {
      content: "";
      transform: rotate(180deg);
      transition: 0.3s transform linear;
    }
    &:after {
      content: "";
      transform: rotate(180deg);
      transition: 0.6s transform linear 0.3s;
    }
  }
  &_with-txt {
    display: inline-flex;
    align-items: center;
    width: auto;
    height: auto;
    background: url(../../images/icons/small_arrow_open.svg) no-repeat center
      right;
    padding-right: 60px;
    font-family: $roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 130%;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: $color_dark_gold;
    transition: color 0.3s linear;
    &_white {
      color: $color_white;
      background: url(../../images/icons/small_arrow_open_white.svg) no-repeat
        center right;
    }
    &:before,
    &:after {
      right: -15px;
    }
    &:hover {
      color: $color_black;
      transition: color 0.3s linear;
    }
  }
}

// Google pay btn black
.gpay-btn-black {
  width: 100%;
  height: 60px;
  border: 1px solid $color_dark_grey_2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color_dark_grey_2;
  transition: background-color 0.2s;
  cursor: pointer;
  &:hover {
    background-color: transparent;
    span::after {
      content: url("../../images/icons/pay.svg");
    }
  }
  @media (max-width: $phone_max) {
    height: 50px;
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-top: 4px;
    &::before {
      content: url("../../images/icons/google.svg");
    }
    &::after {
      content: url("../../images/icons/pay-white.svg");
    }
  }
}

// Google pay btn mobile
.gpay-btn-m {
  width: 100%;
  height: 50px;
  border: 1px solid $color_dark_grey_2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.2s;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-top: 4px;
    &::before {
      content: url("../../images/icons/google.svg");
    }
    &::after {
      content: url("../../images/icons/pay.svg");
    }
  }
  &:active {
    background-color: $color_dark_grey_2;
    span:after {
      content: url("../../images/icons/pay-white.svg");
    }
  }
}

// Animation

@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}
