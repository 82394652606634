@charset "UTF-8";

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
input,
address,
cite,
code,
del,
em,
img,
ins,
kbd,
q,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  overflow-x: hidden;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

button {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}

a {
  text-decoration: none;
}

a[href^=tel] {
  text-decoration: none;
}

/* Sizes */

/* Sizes */

/* Sizes */

/* -----------------------------  Fonts ----------------------------- */

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto/Roboto-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto/Roboto-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto/Roboto-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cormorant";
  src: url("../../fonts/Cormorant/Cormorant-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cormorant";
  src: url("../../fonts/Cormorant/Cormorant-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cormorant";
  src: url("../../fonts/Cormorant/Cormorant-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../../fonts/Inter/Inter-Light.woff2") format("woff2"), url("../../fonts/Inter/Inter-Light.woff") format("woff");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../../fonts/Inter/Inter-Regular.woff2") format("woff2"), url("../../fonts/Inter/Inter-Regular.woff?v=3.19") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../../fonts/Inter/Inter-Medium.woff2") format("woff2"), url("../../fonts/Inter/Inter-Medium.woff") format("woff");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../../fonts/Inter/Inter-Bold.woff2?v=3.19") format("woff2"), url("../../fonts/Inter/Inter-Bold.woff?v=3.19") format("woff");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

body {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 130%;
}

h1,
.h1,
h1 * {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 64px;
  line-height: 60px;
  color: #212121;
}

h2,
.h2,
h2 * {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 36px;
}

h3,
.h3,
h3 * {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 33px;
}

h4,
.h4,
h4 * {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
}

h5,
.h5,
h5 * {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}

.txt__body {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
}

.txt__small-body {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
}

.txt__small-addition {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.txt__cormorant {
  font-family: "Cormorant", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
}

.txt__roboto {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
}

@font-face {
  font-family: "aroma-icons";
  src: url("../../fonts/AromaFontIcons/aroma-icons.eot");
  src: url("../../fonts/AromaFontIcons/aroma-icons.eot") format("embedded-opentype"), url("../../fonts/AromaFontIcons/aroma-icons.ttf") format("truetype"), url("../../fonts/AromaFontIcons/aroma-icons.woff") format("woff"), url("../../fonts/AromaFontIcons/aroma-icons.svg#aroma-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="aroma-ico-"],
[class*=" aroma-ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "aroma-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.aroma-ico-trash:before {
  content: "\e92f";
}

.aroma-ico-arrow_down:before {
  content: "\e900";
}

.aroma-ico-arrow_left:before {
  content: "\e901";
}

.aroma-ico-arrow_left-long:before {
  content: "\e902";
}

.aroma-ico-arrow_right:before {
  content: "\e903";
}

.aroma-ico-arrow_right-long:before {
  content: "\e904";
}

.aroma-ico-arrow_scroll-top:before {
  content: "\e905";
}

.aroma-ico-bell:before {
  content: "\e906";
}

.aroma-ico-box:before {
  content: "\e907";
}

.aroma-ico-calendar-clock:before {
  content: "\e908";
}

.aroma-ico-chat:before {
  content: "\e909";
}

.aroma-ico-checkmark:before {
  content: "\e90a";
}

.aroma-ico-checkmark_reverse:before {
  content: "\e90b";
}

.aroma-ico-close:before {
  content: "\e90c";
}

.aroma-ico-close_big:before {
  content: "\e90d";
}

.aroma-ico-close_simple:before {
  content: "\e90e";
}

.aroma-ico-delivery-box:before {
  content: "\e90f";
}

.aroma-ico-edit:before {
  content: "\e910";
}

.aroma-ico-eye:before {
  content: "\e911";
}

.aroma-ico-facebook:before {
  content: "\e912";
}

.aroma-ico-gamburger:before {
  content: "\e913";
}

.aroma-ico-garanty:before {
  content: "\e914";
}

.aroma-ico-hangout:before {
  content: "\e915";
}

.aroma-ico-heart:before {
  content: "\e916";
}

.aroma-ico-heart_stroke:before {
  content: "\e917";
}

.aroma-ico-instagram:before {
  content: "\e918";
}

.aroma-ico-lens:before {
  content: "\e919";
}

.aroma-ico-mark:before {
  content: "\e91a";
}

.aroma-ico-mark_bold:before {
  content: "\e91b";
}

.aroma-ico-messeger:before {
  content: "\e91c";
}

.aroma-ico-minus:before {
  content: "\e91d";
}

.aroma-ico-parfume:before {
  content: "\e91e";
}

.aroma-ico-phone:before {
  content: "\e91f";
}

.aroma-ico-plus:before {
  content: "\e920";
}

.aroma-ico-present:before {
  content: "\e921";
}

.aroma-ico-quote_left:before {
  content: "\e922";
}

.aroma-ico-quote_right:before {
  content: "\e923";
}

.aroma-ico-shopping-cart:before {
  content: "\e924";
}

.aroma-ico-shopping-cart_stroke:before {
  content: "\e925";
}

.aroma-ico-star:before {
  content: "\e926";
}

.aroma-ico-tag:before {
  content: "\e927";
}

.aroma-ico-tag_diagonal:before {
  content: "\e928";
}

.aroma-ico-telegram:before {
  content: "\e929";
}

.aroma-ico-thumb_down:before {
  content: "\e92a";
}

.aroma-ico-thumb_up:before {
  content: "\e92b";
}

.aroma-ico-triangle_down:before {
  content: "\e92c";
}

.aroma-ico-user_ico:before {
  content: "\e92d";
}

.aroma-ico-viber:before {
  content: "\e92e";
}

.contact-widget {
  font-family: "Inter", sans-serif;
}

.contact-widget__button {
  margin-left: auto;
  padding: 16px;
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  gap: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1px;
  color: #212121;
  background-color: #f2f1f1;
  cursor: pointer;
  opacity: 1;
  transition: opacity .3s;
}

.contact-widget__button.hidden {
  opacity: 0;
  pointer-events: none;
}

.contact-widget__popup {
  position: fixed;
  right: calc(50% - 720px);
  bottom: 0;
  z-index: 98;
  margin-left: auto;
  padding: 24px 32px 32px;
  width: 455px;
  font-size: 16px;
  font-weight: normal;
  background-color: #f2f1f1;
  transform: translateY(100%);
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;
}

.contact-widget__popup-title {
  margin-bottom: 11px;
  padding-top: 11px;
  padding-right: 24px;
  color: #212121;
  font-size: 24px;
  line-height: 28px;
}

.contact-widget__popup-description {
  margin-bottom: 24px;
}

.contact-widget__popup-list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 16px;
  color: #252424;
  font-weight: 500;
}

.contact-widget__popup-list-item {
  padding: 16px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d7d6D5;
}

.contact-widget__popup-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.contact-widget__popup-icon-svg {
  display: block;
}

.contact-widget__popup-link {
  display: grid;
  width: 100%;
  align-items: center;
  grid-auto-flow: column;
  gap: 16px;
  grid-template-columns: repeat(2, max-content);
  text-decoration: none;
  color: #212121 !important;
}

.contact-widget__popup-link--telegram .contact-widget__popup-icon {
  background-color: #0088cc;
}

.contact-widget__popup-link--viber .contact-widget__popup-icon {
  background-color: #7360F2;
}

.contact-widget__popup-tooltip {
  display: flex;
  align-items: center;
  gap: 8px;
}

.contact-widget__popup-close {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 34px;
  right: 32px;
  z-index: 1;
  width: 24px;
  height: 24px;
}

.contact-widget__popup-close-icon {
  width: 14px;
  height: 14px;
}

.contact-widget__callback-btn {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(2, max-content);
  gap: 16px;
  align-items: center;
  width: 100%;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  color: #252424;
  font-weight: 500;
}

.contact-widget__callback-btn .contact-widget__popup-icon {
  background-color: #5E9D54;
}

.contact-widget__button-wrapper {
  position: fixed;
  right: calc(50% - 720px);
  bottom: 0;
  z-index: 99;
}

.contact-widget__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 97;
  display: none;
  background-color: rgba(33, 33, 33, 0.4);
  opacity: 0;
  transition: opacity 0.3s;
}

.contact-widget.open .contact-widget__popup {
  transform: translateY(0);
  opacity: 1;
}

.rngst_phone_button {
  display: none !important;
}

/* Sizes */

/* -----------------------------  Utility Classes ----------------------------- */

.hiden {
  display: none !important;
}

.body-overflow {
  overflow: hidden;
  width: 100%;
}

.txt-align-center {
  text-align: center;
}

/* -----------------------------  backgrounds ----------------------------- */

.bg-grey {
  background: #f3f2ef;
}

.bg-grey_1 {
  background: #f8f8f8;
}

.bg-dark-grey {
  background: #38373d;
}

.bg-faq-form {
  background: #212121;
}

/* ------------------------------- Text ------------------------------- */

.c-white {
  color: #ffffff;
}

.uppercase {
  text-transform: uppercase;
}

/* -----------------------------  containers ----------------------------- */

.aroma-container_fw {
  width: 100%;
  max-width: 100%;
}

.aroma-container {
  max-width: 1440px;
  width: 100%;
  padding: 0 40px;
  margin: 0 auto;
}

.aroma-container_big {
  max-width: 1920px;
  margin: 0 auto;
}

/* Sizes */

/* Sizes */

.btn-white {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  border: 1px solid #212121;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.btn-white span {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #212121;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  transition: color 0.3s ease;
}

.btn-white:active {
  background-color: #212121;
  border-color: #ffffff;
}

.btn-white:active span {
  color: #ffffff;
}

.btn-white:disabled {
  cursor: default;
}

.btn-white:disabled:hover {
  background-color: #ffffff;
}

.btn-white:disabled:hover span {
  color: #212121;
}

.btn-black {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  height: 60px;
  background-color: #212121;
  border: 1px solid #212121;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.btn-black span {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  transition: color 0.3s ease;
}

.btn-black:active {
  background-color: #ffffff;
  border-color: #212121;
}

.btn-black:active span {
  color: #212121;
}

.btn-black:disabled {
  cursor: default;
}

.btn-black:disabled:hover {
  background-color: #212121;
}

.btn-black:disabled:hover span {
  color: #ffffff;
}

.btn {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  text-overflow: ellipsis;
  transition: 0.3s color ease, 0.3s background ease;
  letter-spacing: 0.03em;
  cursor: pointer;
}

.btn * {
  font-weight: 700;
}

.btn_show span {
  color: #212121;
}

.btn_show-more {
  width: 240px;
  height: 60px;
  position: relative;
}

.btn_show-more::before,
.btn_show-more::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  background: #212121;
}

.btn_show-more span::before,
.btn_show-more span::after {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  background: #212121;
}

.btn_show-more::before {
  left: 0;
  top: 0;
  transition: 0.2s width ease-in-out;
  transition-delay: 0.4s;
}

.btn_show-more::after {
  right: 0;
  bottom: 0;
  transition: 0.2s width ease-in-out;
}

.btn_show-more span::before {
  right: 0;
  top: 0;
  transition: 0.2s height ease-in-out;
  transition-delay: 0.6s;
}

.btn_show-more span::after {
  left: 0;
  bottom: 0;
  transition: 0.2s height ease-in-out;
  transition-delay: 0.2s;
}

.btn_show-more:hover span:before {
  height: 0;
  transition: 0.2s height ease-in-out;
}

.btn_show-more:hover:before {
  width: 0;
  transition: 0.2s width ease-in-out;
  transition-delay: 0.2s;
}

.btn_show-more:hover span:after {
  height: 0;
  transition: 0.2s height ease-in-out;
  transition-delay: 0.4s;
}

.btn_show-more:hover:after {
  width: 0;
  transition: 0.2s width ease-in-out;
  transition-delay: 0.6s;
}

.btn_show-more span {
  color: #212121;
}

.btn_wide {
  display: flex;
  width: 100%;
  max-width: 100%;
}

.btn_gold {
  background: #ffe064;
  color: #000000;
}

.btn_header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  padding: 6px 0px;
}

.btn_hover-opacity {
  letter-spacing: 0.03em;
}

.btn_hover-opacity:hover {
  opacity: 0.8;
}

.btn_more {
  color: #735e52;
  background-color: #f3f2ef;
  padding: 22px 60px;
  border: none;
  position: relative;
}

.btn_more::before,
.btn_more::after {
  content: "";
  width: 0;
  height: 1px;
  position: absolute;
  transition: all 0.2s linear;
  background: #735e52;
}

.btn_more span::before,
.btn_more span::after {
  content: "";
  width: 1px;
  height: 0;
  position: absolute;
  transition: all 0.2s linear;
  background: #735e52;
}

.btn_more:hover::before,
.btn_more:hover::after {
  width: 100%;
}

.btn_more:hover span::before,
.btn_more:hover span::after {
  height: 100%;
}

.btn_more::after {
  right: 0;
  bottom: 0;
  transition-delay: 0.6s;
}

.btn_more span::after {
  transition-delay: 0.4s;
  left: 0;
  bottom: 0;
}

.btn_more::before {
  left: 0;
  top: 0;
  transition-delay: 0.2s;
}

.btn_more span::before {
  transition-delay: 0s;
  right: 0;
  top: 0;
}

.btn_more:hover::after {
  transition-delay: 0s;
}

.btn_more:hover span::after {
  transition-delay: 0.2s;
}

.btn_more:hover::before {
  transition-delay: 0.4s;
}

.btn_more:hover span::before {
  transition-delay: 0.6s;
}

.btn_more_visible-borders {
  color: #735e52;
  background-color: transparent;
  padding: 22px 60px;
  border: none;
  position: relative;
}

.btn_more_visible-borders::before,
.btn_more_visible-borders::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  background: #212121;
}

.btn_more_visible-borders span::before,
.btn_more_visible-borders span::after {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  background: #212121;
}

.btn_more_visible-borders::before {
  left: 0;
  top: 0;
  transition: 0.2s width ease-in-out;
  transition-delay: 0.4s;
}

.btn_more_visible-borders::after {
  right: 0;
  bottom: 0;
  transition: 0.2s width ease-in-out;
}

.btn_more_visible-borders span::before {
  right: 0;
  top: 0;
  transition: 0.2s height ease-in-out;
  transition-delay: 0.6s;
}

.btn_more_visible-borders span::after {
  left: 0;
  bottom: 0;
  transition: 0.2s height ease-in-out;
  transition-delay: 0.2s;
}

.btn_more_visible-borders:hover span:before {
  height: 0;
  transition: 0.2s height ease-in-out;
}

.btn_more_visible-borders:hover:before {
  width: 0;
  transition: 0.2s width ease-in-out;
  transition-delay: 0.2s;
}

.btn_more_visible-borders:hover span:after {
  height: 0;
  transition: 0.2s height ease-in-out;
  transition-delay: 0.4s;
}

.btn_more_visible-borders:hover:after {
  width: 0;
  transition: 0.2s width ease-in-out;
  transition-delay: 0.6s;
}

.btn-goto {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  height: 60px;
  background-color: #212121;
  border: 1px solid transparent;
  transition: background-color 0.3s ease;
  cursor: pointer;
  width: 239px;
}

.btn-goto span {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  transition: color 0.3s ease;
}

.btn-goto:active {
  background-color: transparent;
  border-color: #212121;
}

.btn-goto:active span {
  color: #212121;
}

.btn-goto:disabled {
  cursor: default;
}

.btn-goto:disabled:hover {
  background-color: #212121;
}

.btn-goto:disabled:hover span {
  color: #ffffff;
}

.btn-goto-white {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  border: 1px solid transparent;
  transition: background-color 0.3s ease;
  cursor: pointer;
  width: 175px;
}

.btn-goto-white span {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #212121;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  transition: color 0.3s ease;
}

.btn-goto-white:active {
  background-color: #212121;
  border-color: #ffffff;
}

.btn-goto-white:active span {
  color: #ffffff;
}

.btn-goto-white:disabled {
  cursor: default;
}

.btn-goto-white:disabled:hover {
  background-color: #ffffff;
}

.btn-goto-white:disabled:hover span {
  color: #212121;
}

.btn_goto-with-arrow {
  color: #ffffff;
  background-color: #38373d;
  padding: 32px 60px 32px 0;
  border: none;
  position: relative;
  max-width: 441px;
  width: 100%;
}

.btn_goto-with-arrow_transparent {
  background: transparent;
  padding: 0;
  width: auto;
}

.btn_goto-with-arrow .arrow {
  position: relative;
  background: url("../../images/icons/long-arrow.svg") no-repeat center;
  width: 60px;
  height: 15px;
  margin-right: 35px;
}

.btn_goto-with-arrow .arrow:before {
  content: "";
  right: -8px;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  border-radius: 50%;
  transform: translateY(-50%);
  left: 35px;
  border-bottom: 1px solid #c8c8c8;
  transition: 0.3s transform linear;
  transition: 0.3s transform linear, 0.3s -webkit-transform linear;
}

.btn_goto-with-arrow .arrow:after {
  content: "";
  right: -8px;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  transform: translateY(-50%);
  left: 35px;
  border-radius: 50%;
  border-top: 1px solid #c8c8c8;
  transition: 0.6s transform linear 0.3s;
}

.btn_goto-with-arrow:hover {
  color: #ffffff;
}

.btn_goto-with-arrow:hover .arrow:before {
  content: "";
  top: 50%;
  transform: rotate(180deg) translateY(50%);
  transition: 0.3s transform linear;
}

.btn_goto-with-arrow:hover .arrow:after {
  content: "";
  top: 50%;
  transform: rotate(180deg) translateY(50%);
  transition: 0.6s transform linear 0.3s;
}

.btn_goto-svg-arrow {
  color: #fff;
  background-color: #38373d;
  padding: 20px 60px 20px 0;
  border: none;
  position: relative;
  max-width: 441px;
  width: 100%;
}

.btn_goto-svg-arrow span {
  margin-left: 15px;
}

.btn_goto-svg-arrow svg circle {
  cursor: pointer;
  stroke-dasharray: 122px;
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 600ms ease;
  transform: rotate(90deg);
  transform-origin: 56px 19px;
}

.btn_goto-svg-arrow:hover {
  color: #fff;
}

.btn_goto-svg-arrow:hover svg circle {
  stroke-dashoffset: 122px;
}

.btn_goto-svg-arrow_bg-transparent {
  background: transparent;
  color: #fff;
}

.btn_goto-svg-arrow_bg-transparent:hover {
  color: #fff;
}

.btn_goto-svg-arrow_no-txt {
  padding: 0;
  width: auto;
}

.btn_open-svg-arrow {
  cursor: pointer;
}

.btn_open-svg-arrow svg circle {
  stroke-dasharray: 122px;
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 600ms ease;
  transform: rotate(90deg);
  transform-origin: 36px 19px;
}

.btn_open-svg-arrow:hover svg circle {
  stroke-dashoffset: 122px;
}

.btn_open-svg-arrow_white svg rect {
  fill: #ffffff;
}

.btn_open-svg-arrow_with-txt span {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-right: 15px;
  color: #212121;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

.btn_more-svg-vb {
  color: #735e52;
  background-color: transparent;
  padding: 22px 60px;
  border: none;
  position: relative;
  min-height: 60px;
}

.btn_more-svg-vb_bg-grey {
  background: #f3f2ef;
}

.btn_more-svg-vb svg {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  fill: transparent;
}

.btn_more-svg-vb svg rect {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  fill: transparent;
  stroke: #735e52;
  stroke-width: 2px;
  transition: stroke-dashoffset 600ms linear;
  z-index: 1;
  stroke-dasharray: 150 480;
  stroke-dashoffset: 150;
}

.btn_more-svg-vb:hover {
  color: #735e52;
}

.btn_more-svg-vb:hover svg rect {
  stroke-dashoffset: -480;
}

.btn_expand {
  padding: 0;
  background: none;
  outline: none;
}

.btn_expand span {
  position: relative;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  font-size: 12px;
  color: #9d897e;
  font-weight: 700;
  transition: all 0.3s ease-in;
  display: flex;
  align-items: center;
}

.btn_expand span:after {
  content: "";
  display: block;
  width: 7px;
  height: 14px;
  background: url(../../images/icons/arrow_left_sprite.svg#arrow_left_black_gold) no-repeat center;
  transform: rotate(270deg);
  transition: all 0.3s ease-in;
  margin-left: 10px;
}

.btn_expand:hover span {
  color: #38373d;
}

.btn_expand.active span:after {
  transform: rotate(90deg);
}

.btn[disabled] {
  background: #f8f8f8;
}

.btn-collapse {
  border: none;
  background: none;
  outline: none;
  padding: 0;
  text-transform: uppercase;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  color: #212121;
  margin-top: 36px;
}

.btn-collapse.active span:after {
  transform: rotate(90deg);
}

.btn-collapse span {
  display: flex;
  align-items: center;
}

.btn-collapse span:after {
  content: "";
  display: block;
  width: 7px;
  height: 14px;
  background: url(../../images/icons/arrow_left_sprite.svg#arrow_left_black) no-repeat center;
  transform: rotate(270deg);
  margin-left: 10px;
  transition: all 0.3s ease-in;
}

.btn-collapse:hover span {
  color: #38373d;
}

.btn-show {
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
}

.btn-show::before {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 1px;
  transform-origin: center;
  transform: scaleX(1);
  transition-property: transform, opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  opacity: 1;
  background: #212121;
}

.btn-show:hover {
  cursor: pointer;
}

.btn-show:hover:before {
  transform: scaleX(0);
  opacity: 0;
}

.btn-show span {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #212121;
}

.btn-goto-buy {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 239px;
  height: 60px;
  background-color: #212121;
  border: 1px solid #212121;
}

.btn-goto-buy span {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #ffffff;
}

.btn-goto-buy:hover {
  background-color: #ffffff;
}

.btn-goto-buy:hover span {
  color: #212121;
}

.add-to-cart-btn {
  text-transform: uppercase;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s linear;
}

.add-to-cart-btn__icon {
  display: inline-flex;
  width: 22px;
  height: 22px;
  background: url("../../images/icons/cart.svg") no-repeat center;
  background-size: contain;
  position: relative;
  margin-right: 10px;
  transition: border-radius 0.3s linear;
}

.add-to-cart-btn:hover {
  color: #735e52;
}

.add-to-cart-btn:hover .add-to-cart-btn__icon {
  display: inline-flex;
  width: 22px;
  height: 22px;
  background: #735e52;
  background-size: contain;
  background-size: 11px;
  position: relative;
  border-radius: 50%;
  transition: border-radius 0.3s linear;
}

.add-to-cart-btn:hover .add-to-cart-btn__icon:before {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background: url("../../images/icons/cart_white.svg") no-repeat center;
  background-size: contain;
  z-index: 1;
}

.add-to-favorites-btn {
  text-transform: uppercase;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s linear;
}

.add-to-favorites-btn__icon {
  display: inline-flex;
  width: 22px;
  height: 22px;
  background: url("../../images/icons/wishlist.svg") no-repeat center center;
  background-size: contain;
  position: relative;
  margin-right: 10px;
  transition: border-radius 0.3s linear;
}

.add-to-favorites-btn:hover {
  color: #735e52;
}

.add-to-favorites-btn:hover .add-to-favorites-btn__icon {
  display: inline-flex;
  position: relative;
  width: 22px;
  height: 22px;
  background: #735e52;
  background-size: contain;
  background-size: 11px;
  position: relative;
  border-radius: 50%;
  transition: border-radius 0.3s linear;
}

.add-to-favorites-btn:hover .add-to-favorites-btn__icon:before {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 8px;
  background: url("../../images/icons/wishlist_conture_white.svg") no-repeat center center;
  background-size: contain;
  z-index: 1;
}

.link {
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
}

.link_underline-to-center {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #ffffff;
}

.link_underline-to-center:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  background: #ffffff;
  width: 100%;
  height: 1px;
  transform-origin: center;
  transform: scaleX(1);
  transition-property: transform, opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  opacity: 1;
}

.link_underline-to-center:hover {
  color: #ffffff;
}

.link_underline-to-center:hover:before {
  transform: scaleX(0);
  opacity: 0;
}

.link_underline-to-center_black {
  color: #000000;
}

.link_underline-to-center_black:before {
  background: #000000;
}

.link_underline-to-center_black:hover {
  color: #000000;
}

.link_underline-to-center_grey {
  color: #38373d;
}

.link_underline-to-center_grey:before {
  background: #38373d;
}

.link_underline-to-center_grey:hover {
  color: #38373d;
}

.link_underline-to-center_dark-grey {
  color: #212121;
}

.link_underline-to-center_dark-grey:before {
  background: #212121;
}

.link_underline-to-center_dark-grey:hover {
  color: #212121;
}

.link_underline-to-center_gold {
  color: #e4d1c0;
}

.link_underline-to-center_gold:before {
  background: #e4d1c0;
}

.link_underline-to-center_gold:hover {
  color: #e4d1c0;
}

.link_underline-to-center_dark-gold {
  color: #9d897e;
}

.link_underline-to-center_dark-gold:before {
  background: #9d897e;
}

.link_underline-to-center_dark-gold:hover {
  color: #9d897e;
}

.link_underline-to-center_brown {
  color: #735e52;
}

.link_underline-to-center_brown:before {
  background: #735e52;
}

.link_underline-to-center_brown:hover {
  color: #735e52;
}

.link_underline-from-center {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 130%;
  transform: perspective(1px) translateZ(0);
  position: relative;
  overflow: hidden;
}

.link_underline-from-center:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  bottom: -2px;
  background: #ffffff;
  height: 1px;
  transition-property: left, right;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.link_underline-from-center:hover {
  color: #ffffff;
}

.link_underline-from-center:hover:before {
  content: "";
  background: #ffffff;
  left: 0;
  right: 0;
}

.small-arrow-open {
  display: flex;
  width: 57px;
  height: 40px;
  background: url(../../images/icons/small_arrow_open.svg) no-repeat center;
  position: relative;
}

.small-arrow-open_white {
  background: url(../../images/icons/small_arrow_open_white.svg) no-repeat center;
}

.small-arrow-open:before {
  content: "";
  right: -8px;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-bottom: 1px solid #c8c8c8;
  transition: 0.3s transform linear;
}

.small-arrow-open:after {
  content: "";
  right: -8px;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-top: 1px solid #c8c8c8;
  transition: 0.6s transform linear 0.3s;
}

.small-arrow-open:hover:before {
  content: "";
  transform: rotate(180deg);
  transition: 0.3s transform linear;
}

.small-arrow-open:hover:after {
  content: "";
  transform: rotate(180deg);
  transition: 0.6s transform linear 0.3s;
}

.small-arrow-open_with-txt {
  display: inline-flex;
  align-items: center;
  width: auto;
  height: auto;
  background: url(../../images/icons/small_arrow_open.svg) no-repeat center right;
  padding-right: 60px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #9d897e;
  transition: color 0.3s linear;
}

.small-arrow-open_with-txt_white {
  color: #ffffff;
  background: url(../../images/icons/small_arrow_open_white.svg) no-repeat center right;
}

.small-arrow-open_with-txt:before,
.small-arrow-open_with-txt:after {
  right: -15px;
}

.small-arrow-open_with-txt:hover {
  color: #000000;
  transition: color 0.3s linear;
}

.gpay-btn-black {
  width: 100%;
  height: 60px;
  border: 1px solid #212121;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #212121;
  transition: background-color 0.2s;
  cursor: pointer;
}

.gpay-btn-black:hover {
  background-color: transparent;
}

.gpay-btn-black:hover span::after {
  content: url("../../images/icons/pay.svg");
}

.gpay-btn-black span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-top: 4px;
}

.gpay-btn-black span::before {
  content: url("../../images/icons/google.svg");
}

.gpay-btn-black span::after {
  content: url("../../images/icons/pay-white.svg");
}

.gpay-btn-m {
  width: 100%;
  height: 50px;
  border: 1px solid #212121;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.2s;
}

.gpay-btn-m span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-top: 4px;
}

.gpay-btn-m span::before {
  content: url("../../images/icons/google.svg");
}

.gpay-btn-m span::after {
  content: url("../../images/icons/pay.svg");
}

.gpay-btn-m:active {
  background-color: #212121;
}

.gpay-btn-m:active span:after {
  content: url("../../images/icons/pay-white.svg");
}

@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}

/* Sizes */

/* Sizes */

fieldset {
  border: 1px solid #e6e4de;
}

fieldset legend {
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 15px;
  color: #c8c8c8;
  font-weight: 400;
  font-size: 10px;
  font-size: 12px;
}

fieldset input {
  width: 100%;
  background: transparent;
  border: 0;
  padding: 12px 30px;
  padding-bottom: 16px;
  display: inline-flex;
  color: #38373d;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  font-weight: 700;
}

fieldset input:focus {
  outline: none;
}

textarea {
  width: 100%;
  border: 0;
  background: transparent;
  resize: none;
  padding: 15px;
}

a {
  color: #212121;
}

.checkbox-container {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  color: #38373d;
}

.checkbox-container * {
  color: #38373d;
  font-family: "Inter", sans-serif;
  font-size: 10px;
  line-height: 130%;
  transition: color 0.3s linear;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-container .checkmark {
  width: 16px;
  height: 16px;
  display: inline-flex;
  background-color: #ffffff;
  border-radius: 3px;
  margin-right: 8px;
  flex: 0 0 auto;
}

.checkbox-container .checkmark_grey {
  background-color: #f8f8f8;
}

.checkbox-container input:checked ~ .checkmark {
  background: url("./../../images/icons/checked_dark.svg") no-repeat center center, #f8f8f8;
}

.checkbox-container input:checked ~ .checkmark_grey {
  background: url("./../../images/icons/check.svg") no-repeat center center, #f3f2ef;
}

.checkbox-container:hover {
  color: #735e52;
}

.checkbox-container:hover * {
  color: #735e52;
}

.radio-container {
  position: relative;
  display: flex;
  position: relative;
  cursor: pointer;
  user-select: none;
  align-items: center;
  color: #38373d;
  text-transform: uppercase;
  transition: color 0.3s linear;
}

.radio-container * {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  transition: color 0.3s linear;
}

.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radio-container .checkmark {
  width: 18px;
  height: 18px;
  display: inline-flex;
  background-color: #ffffff;
  border-radius: 50%;
  margin-right: 13px;
  border: 1px solid #c8c8c8;
  position: relative;
  flex: 0 0 auto;
}

.radio-container input ~ .checkmark {
  background-color: #ffffff;
}

.radio-container input ~ .checkmark:after {
  content: "";
  position: absolute;
  width: 9px;
  height: 9px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  background: transparent;
  border-radius: 50%;
  transition: all 0.3s linear;
}

.radio-container input:checked ~ .checkmark {
  background-color: #ffffff;
}

.radio-container input:checked ~ .checkmark:after {
  content: "";
  position: absolute;
  width: 9px;
  height: 9px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  background: #212121;
  border-radius: 50%;
}

.radio-container input:checked ~ .checkmark_brown {
  background-color: #ffffff;
}

.radio-container input:checked ~ .checkmark_brown:after {
  content: "";
  position: absolute;
  width: 9px;
  height: 9px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #735e52;
  border-radius: 50%;
}

.radio-volume {
  position: relative;
}

.radio-volume input[type="radio"] {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
  visibility: hidden;
}

.radio-volume input[type="radio"]:focus + label {
  border: 1px solid #212121;
}

.radio-volume input + label {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 51px;
  height: 50px;
  margin-right: 9px;
  border: 1px solid #dadada;
  background-color: #ffffff;
  transition: border 0.2s;
  cursor: pointer;
  user-select: none;
}

.radio-volume input + label:hover {
  border-color: #212121;
}

.radio-volume input:checked + label {
  border-color: #212121;
}

.input-grey {
  border: none;
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}

.input-grey::after {
  color: #ff0000;
  display: none;
  content: "";
  margin-top: 5px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
}

.input-grey input {
  padding: 22px 18px 22px 30px;
  border: 1px solid #dadada;
  color: #212121;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  transition: border 0.2s;
  height: 60px;
}

.input-grey input:hover,
.input-grey input:focus {
  border-color: #212121;
}

.input-grey input:hover + legend,
.input-grey input:focus + legend {
  color: #212121;
}

.input-grey legend {
  position: absolute;
  top: 0;
  left: 15px;
  margin: 0;
  transform: translateY(-50%);
  padding: 0 10px;
  background-color: #ffffff;
  color: #dadada;
  transition: color 0.2s;
}

.input-grey.error input {
  border: 1px solid #ff0000;
}

.input-grey.error:after {
  display: block;
  content: "Це поле обов`язкове для заповнення";
}

.input-grey.error-ru input {
  border: 1px solid #ff0000;
}

.input-grey.error-ru:after {
  display: block;
  content: "Это поле обязательно для заполнения";
}

.input-grey.phone-error input {
  border: 1px solid #ff0000;
}

.input-grey.phone-error:after {
  display: block;
  content: "Невірний формат номеру";
}

.input-grey.phone-error-ru input {
  border: 1px solid #ff0000;
}

.input-grey.phone-error-ru:after {
  display: block;
  content: "Неверный формат номера";
}

.input-grey.email-error input {
  border: 1px solid #ff0000;
}

.input-grey.email-error:after {
  display: block;
  content: "Невірний формат пошти";
}

.input-grey.email-error-ru input {
  border: 1px solid #ff0000;
}

.input-grey.email-error-ru:after {
  display: block;
  content: "Неверный формат почты";
}

.input-default {
  background-color: #f8f8f8;
  height: 50px;
  width: 100%;
  padding: 16px 20px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: #212121;
  border: 1px solid transparent;
}

.input-default::placeholder {
  color: #545454;
}

.input-default:hover,
.input-default:focus {
  border-color: #212121;
}

.checkbox-default {
  display: flex;
  cursor: pointer;
}

.checkbox-default input[type="checkbox"] {
  position: absolute;
  height: 0;
  width: 0;
  visibility: hidden;
}

.checkbox-default input[type="checkbox"]:checked + .checkmark::before {
  background: url("../../images/icons/check-black.svg") no-repeat center #f8f8f8;
}

.checkbox-default .checkmark::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background-color: #f8f8f8;
  border-radius: 5px;
  transform: translateY(-2px);
  cursor: pointer;
}

.checkbox-default .text {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  padding-left: 10px;
}

.user-content {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
  color: #38373d;
}

.user-content p {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 5px;
}

.user-content ul {
  margin-left: 18px;
}

.user-content ul,
.user-content ol {
  margin-top: 10px;
}

.user-content ol li {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  color: #212121;
}

.user-content ol li span {
  color: #38373d;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
}

.user-content ol > li > ol {
  margin-left: 0;
}

.user-content ol li::marker {
  margin-right: 20px;
}

.user-content ol {
  counter-reset: item;
}

.user-content ol > li {
  list-style: none;
  counter-increment: item;
}

.user-content ol > li:before {
  content: counters(item, ".") ". ";
  font-weight: 700;
  margin-right: 10px;
}

.user-content ol > li + li {
  margin-top: 20px;
}

.user-content ol > li > ol {
  counter-reset: item;
}

.user-content ol > li > ol > li {
  list-style: none;
  counter-increment: item;
}

.user-content ol > li > ol > li:before {
  content: counters(item, ".") ". ";
  font-weight: 300;
}

.user-content ol > li > ol > li + li {
  margin-top: 15px;
}

.user-content ol > li > ol > li > ol {
  counter-reset: item;
}

.user-content ol > li > ol > li > ol > li {
  list-style: none;
  counter-increment: item;
}

.user-content ol > li > ol > li > ol > li:before {
  content: counters(item, ".") ". ";
  font-weight: 300;
}

.user-content ol > li p {
  display: inline;
}

.user-content ul {
  margin-left: 0;
}

.user-content ul li {
  position: relative;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  padding-left: 18px;
  color: #212121;
}

.user-content ul li:before {
  display: block;
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: #212121;
}

.user-content li {
  margin-bottom: 15px;
}

.user-content h2 {
  line-height: 120%;
  margin: 1.2em 0 0.6em 0;
}

.user-content h3 {
  margin: 1.5em 0 1.2em 0;
}

.user-content h4 {
  letter-spacing: 0.02em;
  margin: 3.3em 0 1.7em 0;
}

.user-content h6 {
  font-weight: 700;
}

.user-content h1:first-child,
.user-content h2:first-child,
.user-content h3:first-child,
.user-content h4:first-child,
.user-content h5:first-child,
.user-content h6:first-child {
  margin-bottom: 10px;
}

.user-content img,
.user-content video {
  margin: 15px;
}

.user-content blockquote {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 33px;
  text-align: center;
  color: #212121;
  padding: 60px 0;
  margin: 80px 0;
  border-top: 1px solid #e6e4de;
  border-bottom: 1px solid #e6e4de;
  position: relative;
}

.user-content blockquote:before {
  content: "";
  position: absolute;
  top: -10px;
  left: 49.6%;
  transform: translateX(-49.6%);
  width: 30px;
  height: 30px;
  padding-left: 32px;
  padding-right: 32px;
  background: url("/src/images/icons/quotes.svg") no-repeat center #ffffff;
}

.user-content blockquote:after {
  content: "";
  padding-left: 32px;
  padding-right: 32px;
  position: absolute;
  bottom: -20px;
  left: 49.4%;
  width: 30px;
  height: 30px;
  transform: translateX(-49.4%);
  background: url("/src/images/icons/quotes_reverse.svg") no-repeat center #ffffff;
}

.user-content .info-table {
  padding-top: 80px;
  padding-bottom: 80px;
  margin-top: 100px;
}

.user-content .info-table__wrap {
  overflow-x: auto;
}

.user-content table {
  width: 100%;
  min-width: 1201px;
}

.user-content table ol,
.user-content table ul {
  margin-top: 10px;
}

.user-content table ol li,
.user-content table ul li {
  margin-bottom: 5px;
}

.user-content table ol {
  list-style: decimal;
}

.user-content table ol li {
  color: #38373d;
}

.user-content table ol li span {
  color: #000000;
}

.user-content table p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #212121;
  margin-top: 12px;
}

.user-content table .check {
  display: inline-block;
  width: 5px;
  height: 5px;
  background: #38373d;
  border-radius: 50%;
}

.user-content thead h4 {
  margin: 0;
}

.user-content tbody * {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #000000;
}

.user-content th p:first-child {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #000000;
  margin: 0;
}

.user-content tr {
  border-bottom: 1px solid rgba(200, 200, 200, 0.5);
}

.user-content tr td {
  text-align: center;
  vertical-align: middle;
}

.user-content tr td:first-child {
  width: 382px;
  text-align: left;
}

.user-content td,
.user-content th {
  padding: 15px 0 15px 0;
}

.user-content b {
  font-weight: 700;
}

.user-content em {
  font-style: italic;
  font-weight: 400;
}

.user-content .w-50 {
  width: 50%;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  gap: 23px;
  margin-top: 20px;
}

.breadcrumbs li {
  position: relative;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  white-space: nowrap;
  color: #212121;
}

.breadcrumbs li:first-child {
  margin-left: 0;
}

.breadcrumbs li:first-child:before {
  display: none;
}

.breadcrumbs li:before {
  content: "";
  position: absolute;
  display: block;
  width: 3px;
  height: 3px;
  top: 50%;
  left: -13px;
  transform: translateY(-50%);
  border-radius: 50%;
  background-color: #212121;
}

.breadcrumbs li:last-child,
.breadcrumbs li:last-child * {
  color: #aaaaaa;
}

.breadcrumbs li:last-child::before,
.breadcrumbs li:last-child *::before {
  background-color: #aaaaaa;
}

.aroma-small-search {
  width: 100%;
  position: relative;
}

.aroma-small-search input {
  width: 100%;
  border: 0;
  padding: 4.5px 25px 4.5px 8px;
  border-radius: 4px;
  color: #38373d;
}

.aroma-small-search input::placeholder {
  color: #c8c8c8;
}

.aroma-small-search__srch-btn {
  position: absolute;
  top: 7px;
  right: 10px;
  cursor: pointer;
}

.aroma-small-search__srch-btn:hover svg {
  fill: #735e52;
}

.advice {
  display: flex;
  position: fixed;
  width: 60px;
  height: 60px;
  right: 20px;
  bottom: 95px;
  background: #dadada url("../../images/icons/dialog_grey.svg") no-repeat center;
  border-radius: 50%;
  transition: all 0.1s linear;
  z-index: 2;
  cursor: pointer;
}

.advice:hover {
  background-color: #ece0d5;
}

.advice.active {
  background: #e4d1c0 url("../../images/icons/close_gold.svg") no-repeat center;
}

.advice.active:hover {
  background-color: transparent;
}

.advice.active ul {
  opacity: 1;
  z-index: 1;
  visibility: visible;
}

.advice ul {
  display: flex;
  flex-direction: column;
  top: -195px;
  position: absolute;
  transform: translateY(0%);
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: all 0.3s ease-in;
}

.advice ul li {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 5px;
  position: relative;
  background: #e4d1c0;
}

.advice ul li:hover {
  background-color: #ece0d5;
}

.advice ul li a {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.advice__messenger:before,
.advice__viber:before,
.advice__google:before {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  background: url("../../images/icons/helper-social-small-sprite.svg") no-repeat center;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 18px;
  background-size: 16px;
  background-position: 0 0;
}

.advice__viber:before {
  background: url("../../images/icons/helper-social-small-sprite.svg") no-repeat center;
  background-position: 0 -36px;
  background-size: 16px;
}

.advice__google:before {
  background: url("../../images/icons/helper-social-small-sprite.svg") no-repeat center;
  background-position: 0 -16px;
  background-size: 16px;
}

.toggle-slide-item {
  position: relative;
  border-top: 1px solid #dadada;
}

.toggle-slide-item__title {
  color: #212121;
  cursor: pointer;
  padding: 22px 25px 19px;
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
}

.toggle-slide-item__title .arrow {
  display: block;
  position: absolute;
  width: 15px;
  height: 18px;
  top: 50%;
  left: 0;
  background: url("../../images/icons/expand_collapse.svg") no-repeat center;
  background-size: cover;
  transform: translateY(-50%) rotate(0deg);
  transition: 0.3s all linear;
}

.toggle-slide-item__content {
  display: none;
  padding-bottom: 25px;
  padding-top: 25px;
}

.toggle-slide-item.active {
  border-top-color: #212121;
}

.toggle-slide-item.active .toggle-slide-item__content {
  display: block;
}

.toggle-slide-item.active .toggle-slide-item__title {
  color: #212121;
}

.toggle-slide-item.active .arrow {
  transform: translateY(-50%) rotate(180deg);
}

.toggle-slide-item:last-child {
  border-bottom: 1px solid #c8c8c8;
}

.choosed-label {
  display: inline-flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 100px;
  background: #e6e4de;
  border: 1px solid #e6e4de;
  font-size: 12px;
  font-weight: 400;
  transition: all 0.3s linear;
}

.choosed-label_transparent {
  background: transparent;
}

.choosed-label:hover {
  border: 1px solid #aaaaaa;
}

.choosed-label__txt {
  margin-right: 10px;
}

.choosed-label__ico {
  font-size: 9px;
  color: #aaaaaa;
  transition: 0.3s all linear;
  cursor: pointer;
}

.choosed-label__ico:hover {
  color: #000000;
}

.brand-item__img-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 210px;
  height: 210px;
  border: 1px solid #aaaaaa;
  padding: 20px;
  transition: all 0.3s linear;
}

.brand-item__img-wrap img {
  max-width: 100%;
  height: auto;
}

.brand-item__brand-name {
  display: inline-flex;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  text-transform: uppercase;
  font-weight: 700;
  color: #38373d;
  margin-top: 20px;
}

.brand-item__brand-name:hover {
  color: #735e52;
}

.brand-item:hover .brand-item__img-wrap {
  border: 1px solid #735e52;
  cursor: pointer;
}

/* ------------------------------ Video wrapper ----------------------------- */

.video-wrapper {
  position: relative;
  width: 100%;
  height: 390px;
  overflow: hidden;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.video-wrapper__play-btn {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url("../../images/icons/play_btn.svg") no-repeat center;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s linear;
}

.video-wrapper__play-btn:hover {
  transform: translate(-50%, -50%) scale(0.95);
}

.video-wrapper__play-btn svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.aroma-ico-arrow_left-long {
  font-size: 40px;
  margin: 8px 0;
  color: #212121;
}

.divider-horizontal {
  width: 100%;
  height: 1px;
  background-color: #dadada;
}

.products-line__wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  row-gap: 40px;
}

.products-line__wrapper .product-card .product-page__details-price {
  font-size: inherit;
  line-height: inherit;
}

.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: start;
  text-align: center;
  width: 325px;
  background: #ffffff;
  transition: box-shadow 0.3s ease;
}

.product-card:active {
  box-shadow: 0px 8px 20px 0px #74747424;
}

.product-card__small {
  display: flex;
  justify-content: flex-start;
  gap: 22px;
}

.product-card__small-img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 208px;
  height: 180px;
  border: 1px solid #d7d5cf;
}

.product-card__small-img a {
  width: 100%;
  height: 100%;
}

.product-card__small-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product-card__small-content {
  text-align: start;
}

.product-card__small-content h5 {
  letter-spacing: 0.03em;
  color: #212121;
  text-transform: uppercase;
  margin-bottom: 2px;
}

.product-card__small-text {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  color: #212121;
}

.product-card__small .product-card__price {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}

.product-card__small .product-card__price-current {
  color: #212121;
}

.product-card__small .product-card__price-current span {
  display: inline-block;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
}

.product-card__small .product-card__price-current .txt__small-body {
  font-size: 14px;
  text-align: center;
  color: #7d7d7d;
  margin-right: 4px;
}

.product-card__small .product-card__price-old {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #7d7d7d;
  text-decoration: line-through;
}

.product-card__not-available {
  position: relative;
}

.product-card__not-available .product-card__info-hidden::after,
.product-card__not-available::before {
  display: block;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.5);
  filter: blur(5px);
  pointer-events: none;
}

.product-card__not-available .product-card__image-wrapper-heart {
  z-index: 2;
}

.product-card-hot {
  position: relative;
}

.product-card-hot::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  z-index: 2;
  background: url("../../images/icons/badge.svg") no-repeat center;
  background-size: contain;
}

.product-card__image-wrapper {
  position: relative;
  width: 100%;
  height: 366px;
  padding: 0;
  margin-bottom: 16px;
}

.product-card__image-wrapper a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.product-card__image-wrapper a img,
.product-card__image-wrapper a source {
  max-width: 100%;
  max-height: 100%;
}

.product-card__image-wrapper-heart {
  position: absolute;
  display: block;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
}

.product-card__image-wrapper-heart::before,
.product-card__image-wrapper-heart::after {
  position: absolute;
  display: block;
  content: "";
  width: 40px;
  height: 20px;
  background-image: url("../../images/icons/wishlist-black.svg");
  opacity: 0;
}

.product-card__image-wrapper-heart::before {
  top: -8px;
  left: -8px;
}

.product-card__image-wrapper-heart::after {
  bottom: -8px;
  right: -8px;
}

.product-card__image-wrapper-heart.active {
  width: 22.5px;
  height: 20px;
  background: url("../../images/icons/wishlist-black.svg") no-repeat center;
  background-size: contain;
  animation: heartbeat 0.8s ease;
}

.product-card__image-wrapper-heart.loading {
  animation: heartbeat 0.8s ease infinite;
}

.product-card__brand {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #212121;
  margin-bottom: 4px;
}

.product-card__info {
  position: relative;
  width: 100%;
}

.product-card__info-visible .txt__body,
.product-card__info-visible p {
  max-width: 325px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-card__info-visible .txt__body {
  display: flex;
  justify-content: center;
  gap: 4.5px;
  color: #7d7d7d;
}

.product-card__info-visible .txt__body span:first-of-type {
  display: inline-block;
  max-width: 325px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-card__info-visible .txt__body .visible {
  display: inline;
  text-overflow: initial;
}

.product-card__info-visible p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 4px;
}

.product-card__info-visible,
.product-card__info-hidden {
  text-align: center;
}

.product-card__info-hidden {
  display: none;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  box-shadow: 0px 12px 20px 0px #74747424;
  background-color: #ffffff;
}

.product-card__info-hidden .txt__body {
  color: #7d7d7d;
}

.product-card__info-hidden p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  max-width: 325px;
  margin-bottom: 4px;
  text-align: center;
}

.product-card .product-card__price {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 8px 0 16px;
}

.product-card .product-card__price-current {
  color: #212121;
  font-size: 16px;
  font-weight: 300;
}

.product-card .product-card__price-current span {
  display: inline-block;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
}

.product-card .product-card__price-current .txt__small-body {
  font-size: 14px;
  text-align: center;
  color: #7d7d7d;
  margin-right: 4px;
}

.product-card .product-card__price-old {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #7d7d7d;
  text-decoration: line-through;
}

.error-text {
  color: #ff0000;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  opacity: 0.7;
}

.scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}

.header-container[data-is-fixed-on-desktop="true"] {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  z-index: 10;
}

.header-spacer {
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  min-height: 109px;
}

.header-spacer.header-spacer__info {
  min-height: 130px;
}

.header-wrapper {
  max-width: 1920px;
  padding: 0 30px;
  margin: 0 auto;
}

.aroma-header {
  position: relative;
  display: flex;
  border-bottom: 1px solid #e4e4e4;
}

.aroma-header__logo {
  margin: 28px 30px 22px 0;
}

.aroma-header__logo-img {
  width: 110px;
  height: 54px;
}

.aroma-header__right-side {
  width: 100%;
  border-left: 1px solid #e4e4e4;
}

.aroma-header__row:nth-child(1) {
  border-bottom: 1px solid #e4e4e4;
}

.aroma-header__bottom {
  padding: 0 0 0 30px;
  display: flex;
  align-items: center;
}

.aroma-header__top {
  display: flex;
  align-items: center;
  padding: 20px 10px 20px 30px;
}

.aroma-header__top-right,
.aroma-header__top-left {
  display: flex;
  align-items: center;
}

.aroma-header__top-left {
  flex: 1 1;
  gap: 33px;
}

.aroma-header__top-right {
  padding: 1px 0;
  gap: 24px;
}

.aroma-header__top-left-links {
  position: relative;
  display: flex;
  gap: 36px;
  padding-right: 28px;
}

.aroma-header__top-left-links::after {
  position: absolute;
  content: "";
  display: block;
  top: -5px;
  right: 0;
  width: 1px;
  height: 26px;
  background-color: #dadada;
}

.aroma-header__phone {
  color: #212121;
  cursor: pointer;
}

.aroma-header__phone a {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.03em;
}

.aroma-header__login {
  position: relative;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.aroma-header__login-logout span {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #ff0000;
  text-transform: uppercase;
}

.aroma-header__user-online {
  display: none;
}

.aroma-header__wishlist-and-cart {
  position: relative;
  padding: 0 24px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.aroma-header__wishlist-and-cart::after,
.aroma-header__wishlist-and-cart::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 1px;
  height: 26px;
  background-color: #dadada;
}

.aroma-header__wishlist-and-cart::after {
  right: 0;
}

.aroma-header__wishlist-and-cart::before {
  left: 0;
}

.aroma-header__wishlist-and-cart .wishlist,
.aroma-header__wishlist-and-cart .cart {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.aroma-header__wishlist-and-cart .wishlist:hover,
.aroma-header__wishlist-and-cart .cart:hover {
  cursor: pointer;
}

.aroma-header__wishlist-and-cart .wishlist .wishlist-btn,
.aroma-header__wishlist-and-cart .wishlist .cart-btn,
.aroma-header__wishlist-and-cart .cart .wishlist-btn,
.aroma-header__wishlist-and-cart .cart .cart-btn {
  position: relative;
  width: 21px;
  height: 18px;
}

.aroma-header__wishlist-and-cart .wishlist .wishlist-btn .amount,
.aroma-header__wishlist-and-cart .wishlist .cart-btn .amount,
.aroma-header__wishlist-and-cart .cart .wishlist-btn .amount,
.aroma-header__wishlist-and-cart .cart .cart-btn .amount {
  position: absolute;
  display: block;
  border-radius: 100px;
  text-align: center;
  box-sizing: content-box;
  color: #ffffff;
}

.aroma-header__wishlist-and-cart .wishlist .cart-btn,
.aroma-header__wishlist-and-cart .cart .cart-btn {
  top: -2px;
}

.aroma-header__wishlist-and-cart .wishlist .cart-btn .amount,
.aroma-header__wishlist-and-cart .cart .cart-btn .amount {
  right: -8px;
  bottom: -8px;
  padding: 2px;
  min-width: 10px;
  background: #C43A2E;
}

.aroma-header__wishlist-and-cart .wishlist .cart-btn .amount__txt,
.aroma-header__wishlist-and-cart .cart .cart-btn .amount__txt {
  font-family: "Inter", sans-serif;
  font-size: 9px;
  font-weight: 600;
  line-height: normal;
}

.aroma-header__wishlist-and-cart .wishlist .wishlist-btn .amount,
.aroma-header__wishlist-and-cart .cart .wishlist-btn .amount {
  top: -7px;
  left: 85%;
  padding: 5px;
  min-width: 15px;
  background: #e4d1c0;
}

.aroma-header__search-btn-mob {
  display: none;
}

.aroma-header__language-changer a,
.aroma-header__language-changer span {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #b8b6ae;
}

.aroma-header__language-changer a:hover,
.aroma-header__language-changer span:hover {
  color: #212121;
}

.aroma-header__language-changer a:last-child,
.aroma-header__language-changer span:last-child {
  margin-left: 12px;
}

.aroma-header__language-changer a.active,
.aroma-header__language-changer span.active {
  color: #212121;
}

.aroma-header__phone-and-lang-changer {
  display: none;
}

.aroma-header__nav {
  flex: 1;
}

.aroma-header__nav-links {
  display: none;
}

.aroma-header__nav-list {
  display: flex;
  gap: 30px;
}

.aroma-header__nav-item .aroma-header__top-left-link {
  display: none;
}

.aroma-header__nav-item:last-child {
  padding: 0;
  border: none;
}

.aroma-header__nav-item .category-columns {
  display: flex;
  flex-direction: row;
  gap: 78px;
}

.aroma-header__nav-item .category-column {
  width: 267px;
}

.aroma-header__nav-item .category-column__title {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  text-transform: uppercase;
}

.aroma-header__nav-item .category-column__list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 12px;
  transition: max-height 0.3s ease;
}

.aroma-header__nav-item .category-column__list.empty-list {
  display: none;
}

.aroma-header__nav-item .category-column__list .category-column__link_star {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
}

.aroma-header__nav-item .category-column__list .category-column__link_star:after {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  position: relative;
  background: url("../../images/icons/black_star.svg") no-repeat;
}

.aroma-header__nav-item .category-column__link {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.aroma-header__nav-item .promotions {
  display: flex;
  gap: 79px;
  margin-top: 7px;
}

.aroma-header__nav-item .promotions__column {
  width: 209px;
}

.aroma-header__nav-item .promotions__list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 24px;
}

.aroma-header__nav-link {
  position: relative;
  display: inline-flex;
  text-transform: uppercase;
  padding-top: 13px;
  padding-bottom: 14px;
  width: 100%;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.03em;
  color: #212121;
  cursor: pointer;
}

.aroma-header__nav-link.active {
  color: #212121;
}

.aroma-header__nav-link.active:after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: -1px;
  left: 0;
  height: 2px;
  transition: background 0.3s ease;
  background: #212121;
}

.aroma-header__nav-link_stocks {
  color: #ff0000;
}

.aroma-header__nav-link_stocks:hover,
.aroma-header__nav-link_stocks:active {
  color: #ff0000;
}

.aroma-header__nav-link_stocks:before {
  content: "\e928";
  font-family: "aroma-icons";
  font-weight: 400;
  position: relative;
  display: block;
  margin-right: 5px;
  font-size: 10px;
}

.aroma-header__nav-link_present {
  color: #9d897e;
}

.aroma-header__nav-link_present:before {
  content: "\e921";
  font-family: "aroma-icons";
  font-weight: 400;
  position: relative;
  display: block;
  left: -5px;
}

.aroma-header__overlay {
  display: none;
  position: fixed;
  top: calc(100% + 1px);
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  opacity: 70%;
  background: #212121;
}

.aroma-header__overlay.active {
  display: block;
  z-index: 3;
}

.aroma-header__nav-item-drop {
  display: none;
  position: absolute;
  top: calc(100% + 0.5px);
  left: 0;
  width: 100%;
  max-height: calc(100vh - 110px);
  height: auto;
  padding: 24px 40px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s linear;
}

.aroma-header__nav-item-drop.active {
  display: flex;
  opacity: 1;
  visibility: visible;
  z-index: 4;
}

.aroma-header__nav-item-drop::after,
.aroma-header__nav-item-drop::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  background: #f8f8f8;
  width: 1002px;
  height: 100%;
}

.aroma-header__nav-item-drop::after {
  right: -999px;
}

.aroma-header__nav-item-drop::before {
  left: -999px;
}

.aroma-header__drop-left-content {
  display: flex;
  padding: 22px 0 60px;
  width: 100%;
  overflow: auto;
}

.aroma-header__drop-left-content_two-col {
  max-width: 653px;
}

.aroma-header__drop-left-content_two-col .category-columns {
  flex-direction: row;
  width: 100%;
}

.aroma-header__drop-right-content {
  display: flex;
  height: 100%;
  padding: 46px 40px 43px 57px;
  background: #ffffff;
}

.aroma-header__drop-right-content .category-alpfabet {
  max-width: 268px;
}

.aroma-header__drop-right-content .category-alpfabet__title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  display: inline-block;
  margin-bottom: 24px;
}

.aroma-header__drop-right-content .category-alpfabet__content {
  border-top: 1px solid #f8f8f8;
  margin-bottom: 90px;
}

.aroma-header__drop-right-content .category-alpfabet__list {
  display: flex;
  flex-wrap: wrap;
}

.aroma-header__drop-right-content .category-alpfabet__item {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 43px;
  height: 43px;
  border-bottom: 1px solid #f8f8f8;
}

.aroma-header__drop-right-content .category-alpfabet__item::after {
  content: "";
  position: absolute;
  top: 11px;
  right: -1px;
  height: 20px;
  width: 1px;
  background-color: #f8f8f8;
}

.aroma-header__drop-right-content .category-alpfabet__item::before {
  content: "";
  position: absolute;
  top: 11px;
  left: -1px;
  height: 20px;
  width: 1px;
  background-color: #f8f8f8;
}

.aroma-header__drop-right-content .category-alpfabet__link {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #000000;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  padding: 12px 0;
}

.aroma-header__drop-right-content .category-alpfabet__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 268px;
  height: 60px;
  border: 1px solid #212121;
}

.aroma-header__drop-right-content .category-alpfabet__btn span {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  color: #212121;
}

.aroma-header__drop-right-images {
  max-width: 787px;
  height: 100%;
  background: #ffffff;
  padding: 51px 40px 60px 77px;
}

.aroma-header__drop-right-images .image-columns {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.aroma-header__nav-item-back-btn {
  display: none;
}

.aroma-header__category-menu-left {
  display: flex;
  gap: 90px;
  margin-right: 98px;
}

.aroma-header__category-menu-left .categories__list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.aroma-header__category-menu-left .categories__list a {
  white-space: nowrap;
}

.aroma-header__category-menu-left .categories__list .category-column {
  width: fit-content;
}

.aroma-header__category-menu-left .categories__link {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #000000;
  white-space: nowrap;
}

.aroma-header__category-menu-left .categories__link-sale {
  color: #ff0000;
}

.aroma-header__category-menu-right {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-auto-rows: minmax(auto, auto);
  grid-column-gap: 90px;
  grid-row-gap: 20px;
  width: 100%;
  overflow-y: auto;
}

.aroma-header__category-menu-right .category-column {
  width: fit-content;
}

.aroma-header__search-btn {
  height: 18px;
  width: 18px;
  cursor: pointer;
  position: relative;
  margin-right: 10px;
  transition: 0.5s transform ease-in-out;
}

.aroma-header__search-btn:hover {
  transform: rotate(35deg);
}

.aroma-header__search-close {
  cursor: pointer;
  transition: 0.3s transform linear;
  height: 30px;
  width: 30px;
  margin-right: 10px;
  z-index: 1;
  display: flex;
  align-items: center;
}

.aroma-header__search-wrap {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  z-index: 4;
  width: 100%;
  height: 140px;
  background: #ffffff;
  left: 0;
  top: -100%;
  transition: 0.3s top ease, 0.2s visibility linear;
}

.aroma-header__search-wrap.active {
  transition: 0.3s top ease, 0s visibility linear;
  visibility: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
}

.aroma-header__search-wrap.active:before {
  content: "";
  position: absolute;
  display: block;
  left: -49vw;
  width: 50vw;
  height: 100%;
  background: #ffffff;
}

.aroma-header__search-wrap.active:after {
  content: "";
  position: absolute;
  display: block;
  right: -49vw;
  width: 50vw;
  height: 100%;
  background: #ffffff;
}

.aroma-header__search-result {
  opacity: 0;
  visibility: hidden;
  transition: 0.3s opacity linear;
  position: absolute;
  top: 140px;
  background: #f8f8f8;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 5;
}

.aroma-header__search-result::after,
.aroma-header__search-result::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  width: 50vw;
  height: 100%;
  background: #f8f8f8;
}

.aroma-header__search-result:before {
  left: -49vw;
}

.aroma-header__search-result:after {
  right: -49vw;
}

.aroma-header__search-result.active {
  visibility: visible;
  opacity: 1;
}

.aroma-header__search-result-content {
  max-height: 73vh;
  overflow: auto;
  padding: 0 68px 85px 68px;
  z-index: 1;
}

.aroma-header__search-result-more {
  margin-top: 30px;
}

.aroma-header__search-result__name .search-highlight {
  background: #e4d1c0;
}

.aroma-header__search-result .small-product-item-info {
  padding: 30px 0;
  border-bottom: 1px solid #c8c8c8;
  border-radius: 0;
  background: none;
  margin: 0;
}

.aroma-header__search-result .small-product-item-info .search-highlight {
  background: #ffe064;
}

.aroma-header__search-result .small-product-item-info__brand {
  color: #212121;
  margin-bottom: 20px;
  align-self: flex-start;
}

.aroma-header__search-result .small-product-item-info__desc {
  display: flex;
  flex-direction: column;
  margin-left: 25px;
}

.aroma-header__search-result .small-product-item-info__brand:after {
  display: none;
}

.aroma-header__search-result .small-product-item-info__amount {
  display: none;
}

.aroma-header__search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  background: rgba(56, 55, 61, 0.7);
  visibility: hidden;
  opacity: 0;
  height: 100vh;
  transition: 0.3s opacity linear;
}

.aroma-header__search-overlay.active {
  visibility: visible;
  opacity: 1;
  z-index: 3;
}

.aroma-header__search-form {
  width: 100%;
  margin: 0 32px;
}

.aroma-header__search-input {
  padding: 0;
  border: 0;
  color: #000000;
  width: 100%;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 26px;
  line-height: 33px;
}

.aroma-header__search-input::placeholder {
  color: #aaaaaa;
}

.aroma-header__search-ico {
  display: inline-flex;
  width: 30px;
  height: 30px;
  z-index: 1;
  margin-left: 10px;
}

.aroma-header__burger {
  display: none;
}

.aroma-header__mobile-nav {
  display: none;
}

.aroma-header_checkout {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aroma-header_checkout .aroma-header__logo {
  padding-right: 0;
}

.aroma-header__btn-img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.header-info {
  position: relative;
  align-items: center;
  width: 100%;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 17px;
}

.header-info__item {
  padding: 1px 0;
  display: flex;
  align-items: center;
  align-content: center;
  flex-shrink: 0;
  width: 100%;
  height: auto;
  text-align: center;
}

.header-info__item-text {
  margin: 0 auto;
  max-width: 80%;
  letter-spacing: .05em;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
}

.header-info .swiper-wrapper {
  display: flex;
  width: 100%;
}

.header-info .arrow-next,
.header-info .arrow-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  display: grid;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: none;
}

.header-info .arrow-prev {
  left: 56px;
}

.header-info .arrow-next {
  right: 56px;
}

@media (min-width: 768px) {
  .aroma-header__drop-left-content .category-columns .category-column-3 {
    width: 284px;
  }
}

@media (min-width: 1201px) {
  .btn-white:hover {
    background-color: #212121;
    border-color: #ffffff;
  }

  .btn-white:hover span {
    color: #ffffff;
  }

  .btn-black:hover {
    background-color: #ffffff;
    border-color: #212121;
  }

  .btn-black:hover span {
    color: #212121;
  }

  .btn-goto:hover {
    background-color: transparent;
    border-color: #212121;
  }

  .btn-goto:hover span {
    color: #212121;
  }

  .btn-goto-white:hover {
    background-color: #212121;
    border-color: #ffffff;
  }

  .btn-goto-white:hover span {
    color: #ffffff;
  }

  a:hover {
    transition: 0.3s all ease;
    color: #aaaaaa;
  }

  .product-card:hover {
    box-shadow: 0px 8px 20px 0px #74747424;
    z-index: 2;
  }

  .product-card:hover .product-card__info-hidden {
    display: block;
  }

  .aroma-header__nav-link:hover {
    color: #212121;
  }

  .aroma-header__nav-link:hover:after {
    content: "";
    position: absolute;
    width: 100%;
    bottom: -1px;
    left: 0;
    height: 2px;
    transition: background 0.3s ease;
    background: #212121;
    z-index: 5;
  }

  .aroma-header__drop-right-content .category-alpfabet__btn:hover {
    background-color: #000000;
  }

  .aroma-header__drop-right-content .category-alpfabet__btn:hover span {
    color: #ffffff;
  }
}

@media (max-width: 1480px) {
  .contact-widget__popup {
    right: 40px;
  }

  .contact-widget__button-wrapper {
    right: 40px;
  }
}

@media (max-width: 1379px) {
  .aroma-header__drop-right-images .image-columns .info-block-type-1:not(:first-child) {
    margin-left: 0;
  }
}

@media (max-width: 1200px) {
  .contact-widget__button {
    padding: 32px 16px;
    background-color: #f2f1f180;
  }

  .contact-widget__popup {
    right: initial;
    margin: initial;
    padding: 54px 32px 36px;
    width: 100%;
    background-color: #fff;
  }

  .contact-widget__popup-content {
    margin: auto;
    width: 365px;
  }

  .contact-widget__popup-close {
    top: 30px;
    right: 42px;
  }

  .contact-widget__button-wrapper {
    bottom: 25%;
    right: initial;
    left: 0;
    width: auto;
    transform: translateX(0);
  }

  .contact-widget__button-text {
    display: none;
  }

  .contact-widget.open .contact-widget__overlay {
    display: block;
    opacity: 1;
  }

  .aroma-container {
    width: 768px;
    padding: 0 20px;
  }

  .breadcrumbs {
    margin-top: 10px;
  }

  .video-wrapper {
    height: 426px;
  }

  .products-line__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }

  .header-container[data-is-fixed-on-desktop="true"] {
    z-index: 4;
  }

  .header-spacer {
    min-height: 80px;
  }

  .header-spacer.header-spacer__info {
    min-height: 99px;
  }

  .header-wrapper {
    padding: 0 20px;
  }

  .aroma-header__logo {
    margin: 20px 20px 15.5px 0;
  }

  .aroma-header__logo-img {
    width: 82px;
    height: 40px;
  }

  .aroma-header__row {
    padding: 13px 0 14px 20px;
  }

  .aroma-header__row:nth-child(1) {
    border-bottom: none;
  }

  .aroma-header__row.aroma-header__top {
    height: 100%;
  }

  .aroma-header__row.aroma-header__bottom {
    display: none;
  }

  .aroma-header__top-right {
    justify-content: flex-end;
    gap: 16px;
  }

  .aroma-header__top-left-links {
    display: none;
  }

  .aroma-header__wishlist-and-cart {
    padding: 0 16px;
  }

  .aroma-header__search-btn-mob {
    display: block;
    height: 20px;
    width: 20px;
  }

  .aroma-header__language-changer {
    display: none;
  }

  .aroma-header__phone-and-lang-changer {
    display: flex;
  }

  .aroma-header__nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 358px;
    background: #ffffff;
    overflow: auto;
    height: 100vh;
    transform: translateX(-100%);
    transition: all 0.3s;
  }

  .aroma-header__nav.active {
    transform: translateX(0);
  }

  .aroma-header__nav.width-100 {
    width: 100%;
    transform: translateX(0);
    animation: moveX 0.4s ease;
  }

@keyframes moveX {
    0%, 100% {
      transform: translateX(0);
    }

    50% {
      transform: translateX(-100vw);
    }

    50.1% {
      transform: translateX(100vw);
    }
}

  .aroma-header__nav-links {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
  }

  .aroma-header__nav-links .aroma-header__top-left-link {
    display: inline-block;
    padding: 15px 0 13px;
    border-bottom: 1px solid #e6e4de;
  }

  .aroma-header__nav-links .aroma-header__top-left-link:first-child {
    border-top: 1px solid #e6e4de;
  }

  .aroma-header__nav-links .aroma-header__top-left-link:last-child {
    border-bottom: none;
  }

  .aroma-header__nav-list {
    flex-direction: column;
    gap: 15px;
    padding: 0 20px;
  }

  .aroma-header__nav-item .aroma-header__top-left-link {
    display: inline-flex;
    padding-bottom: 15px;
  }

  .aroma-header__nav-item .promotions {
    margin: 0;
    gap: 140px;
  }

  .aroma-header__nav-item {
    border-bottom: 1px solid #e6e4de;
  }

  .aroma-header__nav-item:first-child {
    border-top: 1px solid #e6e4de;
    padding-top: 15px;
  }

  .aroma-header__nav-link {
    padding-bottom: 13px;
    padding-top: 0px;
    font-size: 14px;
    line-height: 18px;
  }

  .aroma-header__nav-item-drop::after,
  .aroma-header__nav-item-drop::before {
    display: none;
  }

  .aroma-header__nav-item-drop {
    top: 0;
    overflow: auto;
    flex-direction: column;
    max-height: 100vh;
    height: 100%;
    width: 100%;
    padding: 0;
  }

  .aroma-header__drop-left-content {
    padding: 46px 40px;
    order: 2;
    height: initial;
    flex: 0 0 auto;
  }

  .aroma-header__drop-left-content:before {
    display: none;
  }

  .aroma-header__drop-left-content .category-column {
    max-width: 298px;
    padding-right: 0;
  }

  .aroma-header__drop-left-content .category-columns {
    max-width: 664px;
    flex-wrap: wrap;
  }

  .aroma-header__drop-left-content_two-col {
    order: 0;
    padding: 53px 40px 63px;
    max-width: 100%;
  }

  .aroma-header__drop-right-content {
    padding: 46px 46px 32px 58px;
  }

  .aroma-header__drop-right-content .category-alpfabet {
    max-width: 100%;
  }

  .aroma-header__drop-right-content .category-alpfabet__content {
    margin-bottom: 30px;
  }

  .aroma-header__drop-right-content .category-alpfabet__list {
    gap: 1px;
  }

  .aroma-header__drop-right-content .category-alpfabet__btn {
    height: 50px;
  }

  .aroma-header__drop-right-content .image-columns .info-block-type-1 {
    margin-bottom: 20px;
  }

  .aroma-header__drop-right-images .image-columns {
    flex-direction: column;
  }

  .aroma-header__drop-right-images {
    padding: 40px 54px 40px 40px;
  }

  .aroma-header__drop-right-images .image-columns {
    flex-direction: row;
    gap: 24px;
  }

  .aroma-header__drop-right-images .info-block-type-1__image {
    max-width: 325px;
  }

  .aroma-header__drop-right-images .info-block-type-1__image img {
    width: 325px;
    height: 325px;
  }

  .aroma-header__nav-item-back-btn {
    display: flex;
    align-items: center;
    background: #ffffff;
    color: #aaaaaa;
    padding-left: 20px;
    height: 80px;
  }

  .aroma-header__nav-item-back-btn .aroma-ico-arrow_left-long {
    font-size: 40px;
    margin: 20px 0;
    color: #212121;
  }

  .aroma-header__nav-item-back-btn .aroma-header__nav-link {
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.03em;
    padding: 19px 0;
    margin-left: 62px;
    margin-right: auto;
  }

  .aroma-header__category-menu-left {
    padding: 40px;
    margin-right: 0;
    border-bottom: 1px solid #dadada;
  }

  .aroma-header__category-menu-right {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(auto, auto);
    grid-column-gap: 20px;
    grid-row-gap: 40px;
    padding: 46px 40px;
    overflow-y: initial;
  }

  .aroma-header__search-close {
    margin-right: 0;
  }

  .aroma-header__search-wrap {
    height: 80px;
  }

  .aroma-header__search-ico {
    margin-left: 20px;
    width: 25px;
    height: 25px;
  }

  .aroma-header__burger {
    display: flex;
    justify-content: center;
    width: 24px;
  }

  .aroma-header__mobile-nav {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 4;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s linear;
  }

  .aroma-header__mobile-nav.active {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s linear;
  }
}

@media all and (max-width: 1200px) {
  .aroma-header__search-result {
    top: 80px;
  }

  .aroma-header__search-result-content {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 767px) {
  h1,
  .h1,
  h1 * {
    font-size: 40px;
    line-height: 45px;
  }

  h2,
  .h2,
  h2 * {
    font-size: 26px;
    line-height: 30px;
  }

  h3,
  .h3,
  h3 * {
    font-size: 22px;
    line-height: 27px;
  }

  .contact-widget__popup {
    left: 0;
    padding: 24px 32px 32px;
  }

  .contact-widget__popup-close {
    top: 24px;
    right: 32px;
  }

  .f-width-mobile {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  .aroma-container {
    width: 100%;
  }

  .btn-white {
    height: 50px;
  }

  .btn-black {
    height: 50px;
  }

  .btn_show-more {
    height: 50px;
  }

  .btn-goto {
    height: 50px;
  }

  .btn-goto-white {
    height: 50px;
  }

  .btn-goto-white {
    width: 100%;
  }

  .gpay-btn-black {
    height: 50px;
  }

  .radio-container * {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
    text-transform: none;
  }

  .input-grey input {
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .user-content blockquote {
    font-size: 22px;
    line-height: 27px;
  }

  .user-content blockquote {
    margin: 30px 0;
    padding: 25px 0;
  }

  .user-content blockquote:before {
    width: 19px;
    height: 19px;
    padding-left: 23px;
    padding-right: 23px;
  }

  .user-content blockquote:after {
    width: 19px;
    height: 19px;
    bottom: -12px;
    padding-left: 23px;
    padding-right: 23px;
  }

  .user-content {
    width: 100%;
    height: auto;
  }

  .breadcrumbs {
    overflow-x: auto;
  }

  .breadcrumbs::-webkit-scrollbar {
    display: none;
  }

  .advice {
    width: 50px;
    height: 50px;
  }

  .advice ul li {
    width: 50px;
    height: 50px;
  }

  .brand-item__img-wrap {
    width: 150px;
    height: 150px;
  }

  .video-wrapper {
    height: 204px;
  }

  .products-line__wrapper {
    row-gap: 20px;
  }

  .product-card__small {
    gap: 20px;
  }

  .product-card__small-img {
    width: 108px;
    height: 92px;
  }

  .product-card__small-content h5 {
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }

  .product-card__small-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }

  .product-card__small .product-card__price-current span {
    line-height: 23px;
  }

  .product-card__small .product-card__price-current span:first-of-type {
    font-weight: 300;
    font-size: 12px;
  }

  .product-card-hot::before {
    width: 28px;
    height: 28px;
  }

  .product-card__image-wrapper-heart.active {
    width: 19px;
    height: 17px;
  }

  .product-card {
    width: 150px;
  }

  .product-card__image-wrapper {
    width: 150px;
    height: 180px;
    margin-bottom: 8px;
  }

  .product-card__image-wrapper-heart {
    top: 8px;
    right: 8px;
    width: 20px;
    height: 20px;
  }

  .product-card__image-wrapper-heart svg {
    width: 19px;
    height: 17px;
  }

  .product-card__brand {
    font-size: 12px;
    line-height: 16px;
  }

  .product-card__info-visible .txt__body,
  .product-card__info-visible p,
  .product-card__info-hidden .txt__body,
  .product-card__info-hidden p {
    max-width: 150px;
    font-size: 12px;
    line-height: 15px;
  }

  .product-card .product-card__price {
    margin: 8px 0;
  }

  .product-card .product-card__price-current span {
    line-height: 23px;
  }

  .product-card .product-card__price-current span:first-of-type {
    font-weight: 300;
    font-size: 12px;
  }

  .header-spacer {
    min-height: 57px;
  }

  .header-spacer.header-spacer__info {
    min-height: 76px;
  }

  .aroma-header__logo {
    position: absolute;
    top: 13px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
  }

  .aroma-header__logo-img {
    width: 67px;
    height: 33px;
  }

  .aroma-header__right-side {
    border-left: none;
  }

  .aroma-header__row {
    padding: 0;
  }

  .aroma-header__top-left {
    display: none;
  }

  .aroma-header__top-right {
    justify-content: flex-start;
    width: 100%;
    margin: 15px 0;
    gap: 0;
  }

  .aroma-header__login {
    margin-left: 38px;
  }

  .aroma-header__wishlist-and-cart {
    position: absolute;
    right: 0;
    gap: 14px;
    padding: 0;
  }

  .aroma-header__wishlist-and-cart::after,
  .aroma-header__wishlist-and-cart::before {
    display: none;
  }

  .aroma-header__wishlist-and-cart .wishlist .wishlist-btn .amount {
    border: 0;
    font-size: 8px;
    padding: 1px;
    left: 50%;
  }

  .aroma-header__search-btn-mob {
    margin-left: 14px;
  }

  .aroma-header__nav {
    width: 298px;
  }

  .aroma-header__nav-item .category-columns {
    flex-direction: column;
    gap: 40px;
  }

  .aroma-header__nav-item .category-column__list {
    gap: 16px;
  }

  .aroma-header__nav-item .category-column__link {
    font-size: 14px;
    line-height: 17px;
  }

  .aroma-header__nav-item .category-column.active .category-column__title-arrow {
    transform: rotate(-180deg);
  }

  .aroma-header__nav-item .category-column.active .category-column__list {
    max-height: 1000px;
    gap: 16px;
    padding: 0 20px 20px 42px;
  }

  .aroma-header__nav-item .promotions {
    flex-direction: column;
    gap: 24px;
    margin-top: 0;
  }

  .aroma-header__nav-item .promotions__column {
    width: 100%;
  }

  .aroma-header__nav-item .promotions__list {
    gap: 16px;
  }

  .aroma-header__nav-item .promotions .category-column__title {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-transform: none;
  }

  .aroma-header__nav-item .promotions .category-column__link {
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
  }

  .aroma-header__nav-item {
    position: relative;
  }

  .aroma-header__nav-item:not(:first-child) {
    padding-left: 24px;
  }

  .aroma-header__nav-item:not(:first-child)::before {
    position: absolute;
    left: 0;
    top: 5px;
    content: "";
    display: block;
    width: 15px;
    height: 8px;
    background: url("../../images/icons/expand_collapse.svg") no-repeat center;
    transition: transform 0.3s ease-in-out;
  }

  .aroma-header__nav-item.active:not(:first-child)::before {
    transform: rotate(-180deg);
  }

  .aroma-header__nav-item.active .aroma-header__nav-item-drop {
    max-height: fit-content;
    padding-bottom: 17px;
    height: fit-content;
  }

  .aroma-header__mn-close {
    display: block;
    position: absolute;
    top: 20px;
    right: 23px;
    font-size: 15px;
    color: #ffffff;
  }

  .aroma-header__nav-item-drop {
    position: static;
    display: flex;
    max-height: 0;
    opacity: 1;
    visibility: visible;
    background: #ffffff;
  }

  .aroma-header__drop-left-content {
    flex-direction: column;
    padding: 30px 0 0;
  }

  .aroma-header__drop-left-content .category-column {
    max-width: 100%;
  }

  .aroma-header__drop-left-content_two-col {
    order: 0;
    padding: 20px 20px 40px;
  }

  .aroma-header__drop-right-content {
    border-top: 1px solid #f8f8f8;
    order: 1;
    height: initial;
    flex: 0 0 auto;
    padding: 0;
    flex-direction: column;
  }

  .aroma-header__drop-right-content:before {
    display: none;
  }

  .aroma-header__drop-right-content .category-alpfabet__content {
    margin-bottom: 30px;
  }

  .aroma-header__drop-right-content .category-alpfabet__list {
    gap: 0;
  }

  .aroma-header__drop-right-content .category-alpfabet__btn {
    width: 230px;
    height: 50px;
  }

  .aroma-header__drop-right-images {
    padding: 20px 20px 30px 20px;
  }

  .aroma-header__drop-right-images .image-columns {
    gap: 20px;
    flex-direction: column;
  }

  .aroma-header__drop-right-images .info-block-type-1__image img {
    width: 258px;
    height: 158px;
  }

  .aroma-header__nav-item-back-btn {
    display: none;
    height: 56px;
  }

  .aroma-header__nav-item-back-btn .aroma-ico-arrow_left-long {
    margin: 8px 0;
  }

  .aroma-header__category-menu-left {
    padding: 0;
    border-bottom: none;
  }

  .aroma-header__category-menu-left .categories__list {
    gap: 16px;
  }

  .aroma-header__category-menu-right {
    display: flex;
    padding: 0;
    flex-direction: column;
    gap: 0;
  }

  .aroma-header__category-menu-right .category-column {
    width: 100%;
  }

  .aroma-header__category-menu-right .category-column span {
    display: inline-block;
    position: relative;
    width: 100%;
    padding: 16px 0 0;
  }

  .aroma-header__category-menu-right .category-column span .category-column__title-arrow {
    display: none;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    height: 8px;
    padding: 0;
    background: url("../../images/icons/expand_collapse.svg") no-repeat center;
    transition: transform 0.3s ease-in-out;
    border-top: none;
  }

  .aroma-header__category-menu-right .category-column span .category-column__title-arrow::after {
    display: block;
    content: "";
    position: absolute;
    left: -5px;
    top: -10px;
    width: 25px;
    height: 25px;
    background: transparent;
  }

  .aroma-header__category-menu-right .category-column span.empty-list .category-column__title-arrow {
    display: none;
  }

  .aroma-header__category-menu-right .category-column ul {
    margin-top: 16px;
    padding: 0 0 0 16px;
  }

  .aroma-header__search-wrap {
    height: 68px;
  }

  .aroma-header__search-form {
    margin: 0 40px;
  }

  .aroma-header__search-form {
    margin: 0 22px;
  }

  .aroma-header__search-input {
    font-size: 22px;
    line-height: normal;
  }

  .aroma-header__search-ico {
    margin-left: 3px;
    width: 30px;
    height: 30px;
  }

  .aroma-header__burger {
    position: absolute;
    left: 0;
  }

  .header-info {
    margin-top: -1px;
  }

  .header-info .arrow-prev {
    left: 16px;
  }

  .header-info .arrow-next {
    right: 16px;
  }
}

@media all and (max-width: 767px) {
  .btn-collapse {
    margin-top: 23px;
  }

  .aroma-header__search-result {
    top: 68px;
  }

  .aroma-header__search-result-content {
    padding: 10px 0 65px 0;
  }

  .aroma-header__search-result-mb-separator {
    width: 65px;
    margin-right: 20px;
  }

  .aroma-header__search-result-more {
    margin-top: 20px;
  }

  .aroma-header__search-result .small-product-item-info {
    align-items: flex-start;
    padding: 20px 0 26px;
  }

  .aroma-header__search-result .small-product-item-info__brand {
    margin-bottom: 5px;
  }

  .aroma-header__search-result .small-product-item-info__desc {
    margin-left: 0;
  }
}

@media (max-width: 375px) {
  .header-info .arrow-prev {
    left: 6px;
  }

  .header-info .arrow-next {
    right: 6px;
  }
}

@media (min-width: 320px) and (max-width: 359px) {
  .aroma-container {
    padding: 0 5px;
  }
}

@media (min-width: 1301px) and (max-width: 1404px) {
  .aroma-header__nav-list {
    gap: 14px;
  }
}

@media (min-width: 1201px) and (max-width: 1300px) {
  .aroma-header__nav-list {
    gap: 10px;
  }
}

@media (min-width: 767px) and (max-width: 1200px) {
  .aroma-header .ps__thumb-y {
    width: 5px;
    background: #dadada;
    opacity: 1;
    max-height: 484px !important;
  }

  .aroma-header .ps__rail-y {
    position: fixed !important;
    top: 125px !important;
    right: 40px;
    width: 5px;
    background: #ffffff;
    opacity: 1;
    max-height: 484px !important;
  }
}