@import "../uikit/vars/vars";

/* -----------------------------  Utility Classes ----------------------------- */

.hiden {
  display: none !important;
}

.body-overflow {
  overflow: hidden;
  width: 100%;
}

.txt-align-center {
  text-align: center;
}

.f-width-mobile {
  @media (max-width: $phone_max) {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
}

/* -----------------------------  backgrounds ----------------------------- */

.bg-grey {
  background: $color_bg_grey;
}

.bg-grey_1 {
  background: $color_white_smoke;
}

.bg-dark-grey {
  background: $color_grey;
}

.bg-faq-form {
  background: $color_dark_grey_2;
}

/* ------------------------------- Text ------------------------------- */

.c-white {
  color: $color_white;
}

.uppercase {
  text-transform: uppercase;
}

/* -----------------------------  containers ----------------------------- */

.aroma-container_fw {
  width: 100%;
  max-width: 100%;
}

.aroma-container {
  max-width: 1440px;
  width: 100%;
  padding: 0 40px;
  margin: 0 auto;

  @media (max-width: $tablet-max) {
    width: 768px;
    padding: 0 20px;
  }

  @media (max-width: $phone-max) {
    width: 100%;
  }

  @media (min-width: 320px) and (max-width: 359px) {
    padding: 0 5px;
  }
}

.aroma-container_big {
  max-width: 1920px;
  margin: 0 auto;
}
