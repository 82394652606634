.contact-widget {
    font-family: $inter;

    &__button {
        margin-left: auto;
        padding: 16px;
        display: grid;
        align-items: center;
        grid-auto-flow: column;
        gap: 12px;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 1px;
        color: $color_dark_grey_2;
        background-color: #f2f1f1;
        cursor: pointer;
        opacity: 1;
        transition: opacity .3s;

        @media (max-width: $tablet-max) {
            padding: 32px 16px;
            background-color: #f2f1f180;
        }

        &.hidden {
            opacity: 0;
            pointer-events: none;
        }
    }

    &__popup {
        position: fixed;
        right: calc(50% - 720px);
        bottom: 0;
        z-index: 98;
        margin-left: auto;
        padding: 24px 32px 32px;
        width: 455px;
        font-size: 16px;
        font-weight: normal;
        background-color: #f2f1f1;
        transform: translateY(100%);
        opacity: 0;
        transition: transform 0.3s, opacity 0.3s;

        @media (max-width: 1480px) {
            right: 40px;
        }

        @media (max-width: $tablet-max) {
            right: initial;
            margin: initial;
            padding: 54px 32px 36px;
            width: 100%;
            background-color: #fff;
        }

        @media (max-width: $phone-max) {
            left: 0;
            padding: 24px 32px 32px;
        }

        &-content {
            @media (max-width: $tablet-max) {
                margin: auto;
                width: 365px;
            }
        }

        &-title {
            margin-bottom: 11px;
            padding-top: 11px;
            padding-right: 24px;
            color: $color_dark_grey_2;
            font-size: 24px;
            line-height: 28px;
        }

        &-description {
            margin-bottom: 24px;
        }

        &-list {
            margin: 0;
            padding: 0;
            list-style: none;
            font-size: 16px;
            color: #252424;
            font-weight: 500;

            &-item {
                padding: 16px 0;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #d7d6D5;
            }
        }

        &-icon {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;

            &-svg {
                display: block;
            }
        }

        &-link {
            display: grid;
            width: 100%;
            align-items: center;
            grid-auto-flow: column;
            gap: 16px;
            grid-template-columns: repeat(2, max-content);
            text-decoration: none;
            color: #212121 !important;

            &--telegram {
                .contact-widget__popup-icon {
                    background-color: #0088cc;
                }
            }

            &--viber {
                .contact-widget__popup-icon {
                    background-color: #7360F2;
                }
            }
        }

        &-tooltip {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        &-close {
            background: none;
            border: none;
            cursor: pointer;
            position: absolute;
            top: 34px;
            right: 32px;
            z-index: 1;
            width: 24px;
            height: 24px;

            @media (max-width: $tablet-max) {
                top: 30px;
                right: 42px;
            }

            @media (max-width: $phone-max) {
                top: 24px;
                right: 32px;
            }

            &-icon {
                width: 14px;
                height: 14px;
            }
        }
    }

    &__callback-btn {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: repeat(2, max-content);
        gap: 16px;
        align-items: center;
        width: 100%;
        font-family: $inter;
        font-size: 16px;
        color: #252424;
        font-weight: 500;

        .contact-widget__popup-icon {
            background-color: #5E9D54;
        }
    }

    &__button-wrapper {
        position: fixed;
        right: calc(50% - 720px);
        bottom: 0;
        z-index: 99;

        @media (max-width: 1480px) {
            right: 40px;
        }

        @media (max-width: $tablet-max) {
            bottom: 25%;
            right: initial;
            left: 0;
            width: auto;
            transform: translateX(0);
        }
    }

    &__button-text {
        @media (max-width: $tablet-max) {
            display: none;
        }
    }

    &__overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 97;
        display: none;
        background-color: rgba(33, 33, 33, 0.4);
        opacity: 0;
        transition: opacity 0.3s;
    }

    &.open {
        .contact-widget {
            &__overlay {
                @media (max-width: $tablet-max) {
                    display: block;
                    opacity: 1;
                }
            }

            &__popup {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }
}

.rngst_phone_button {
    display: none !important;
}
