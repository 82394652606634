//Aroma icon fonts
@font-face {
  font-family: "aroma-icons";
  src: url("../../fonts/AromaFontIcons/aroma-icons.eot");
  src: url("../../fonts/AromaFontIcons/aroma-icons.eot") format("embedded-opentype"),
    url("../../fonts/AromaFontIcons/aroma-icons.ttf") format("truetype"),
    url("../../fonts/AromaFontIcons/aroma-icons.woff") format("woff"),
    url("../../fonts/AromaFontIcons/aroma-icons.svg#aroma-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="aroma-ico-"],
[class*=" aroma-ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "aroma-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.aroma-ico-trash:before {
  content: "\e92f";
}
.aroma-ico-arrow_down:before {
  content: "\e900";
}
.aroma-ico-arrow_left:before {
  content: "\e901";
}
.aroma-ico-arrow_left-long:before {
  content: "\e902";
}
.aroma-ico-arrow_right:before {
  content: "\e903";
}
.aroma-ico-arrow_right-long:before {
  content: "\e904";
}
.aroma-ico-arrow_scroll-top:before {
  content: "\e905";
}
.aroma-ico-bell:before {
  content: "\e906";
}
.aroma-ico-box:before {
  content: "\e907";
}
.aroma-ico-calendar-clock:before {
  content: "\e908";
}
.aroma-ico-chat:before {
  content: "\e909";
}
.aroma-ico-checkmark:before {
  content: "\e90a";
}
.aroma-ico-checkmark_reverse:before {
  content: "\e90b";
}
.aroma-ico-close:before {
  content: "\e90c";
}
.aroma-ico-close_big:before {
  content: "\e90d";
}
.aroma-ico-close_simple:before {
  content: "\e90e";
}
.aroma-ico-delivery-box:before {
  content: "\e90f";
}
.aroma-ico-edit:before {
  content: "\e910";
}
.aroma-ico-eye:before {
  content: "\e911";
}
.aroma-ico-facebook:before {
  content: "\e912";
}
.aroma-ico-gamburger:before {
  content: "\e913";
}
.aroma-ico-garanty:before {
  content: "\e914";
}
.aroma-ico-hangout:before {
  content: "\e915";
}
.aroma-ico-heart:before {
  content: "\e916";
}
.aroma-ico-heart_stroke:before {
  content: "\e917";
}
.aroma-ico-instagram:before {
  content: "\e918";
}
.aroma-ico-lens:before {
  content: "\e919";
}
.aroma-ico-mark:before {
  content: "\e91a";
}
.aroma-ico-mark_bold:before {
  content: "\e91b";
}
.aroma-ico-messeger:before {
  content: "\e91c";
}
.aroma-ico-minus:before {
  content: "\e91d";
}
.aroma-ico-parfume:before {
  content: "\e91e";
}
.aroma-ico-phone:before {
  content: "\e91f";
}
.aroma-ico-plus:before {
  content: "\e920";
}
.aroma-ico-present:before {
  content: "\e921";
}
.aroma-ico-quote_left:before {
  content: "\e922";
}
.aroma-ico-quote_right:before {
  content: "\e923";
}
.aroma-ico-shopping-cart:before {
  content: "\e924";
}
.aroma-ico-shopping-cart_stroke:before {
  content: "\e925";
}
.aroma-ico-star:before {
  content: "\e926";
}
.aroma-ico-tag:before {
  content: "\e927";
}
.aroma-ico-tag_diagonal:before {
  content: "\e928";
}
.aroma-ico-telegram:before {
  content: "\e929";
}
.aroma-ico-thumb_down:before {
  content: "\e92a";
}
.aroma-ico-thumb_up:before {
  content: "\e92b";
}
.aroma-ico-triangle_down:before {
  content: "\e92c";
}
.aroma-ico-user_ico:before {
  content: "\e92d";
}
.aroma-ico-viber:before {
  content: "\e92e";
}
