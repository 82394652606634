@import "../uikit/vars/vars";
@import "../uikit/mixins";

//Fieldset
fieldset {
  border: 1px solid $color_grey_3;
  legend {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 15px;
    color: $color_dark_grey;
    font-weight: 400;
    font-size: 10px;
    font-size: 12px;
  }
  input {
    width: 100%;
    background: transparent;
    border: 0;
    padding: 12px 30px;
    padding-bottom: 16px;
    display: inline-flex;
    color: $color_grey;
    &:focus {
      outline: none;
    }
    @include txt__roboto;
    font-weight: 700;
  }
}

textarea {
  width: 100%;
  border: 0;
  background: transparent;
  resize: none;
  padding: 15px;
}

// Links

a {
  color: $color_dark_grey_2;

  @media (min-width: $desktop_min) {
    &:hover {
      transition: 0.3s all ease;
      color: $color_grey_2;
    }
  }
}

// Checkboxes

.checkbox-container {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  color: $color_grey;
  * {
    color: $color_grey;
    font-family: $inter;
    font-size: 10px;
    line-height: 130%;
    transition: color 0.3s linear;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    width: 16px;
    height: 16px;
    display: inline-flex;
    background-color: $color_white;
    border-radius: 3px;
    margin-right: 8px;
    flex: 0 0 auto;
    &_grey {
      background-color: $color_white_smoke;
    }
  }
  input:checked ~ .checkmark {
    background: url("./../../images/icons/checked_dark.svg") no-repeat center
        center,
      $color_white_smoke;
  }
  input:checked ~ .checkmark_grey {
    background: url("./../../images/icons/check.svg") no-repeat center center,
      $color_bg_grey;
  }
  &:hover {
    color: $color_brown;
    * {
      color: $color_brown;
    }
  }
}

// Radio
.radio-container {
  position: relative;
  display: flex;
  position: relative;
  cursor: pointer;
  user-select: none;
  align-items: center;
  color: $color_grey;
  text-transform: uppercase;
  transition: color 0.3s linear;
  * {
    @include h6;
    letter-spacing: 0.03em;
    transition: color 0.3s linear;
    @media (max-width: $phone_max) {
      @include custom-font-inter(400, 14px, normal);
      text-transform: none;
    }
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    width: 18px;
    height: 18px;
    display: inline-flex;
    background-color: $color_white;
    border-radius: 50%;
    margin-right: 13px;
    border: 1px solid $color_dark_grey;
    position: relative;
    flex: 0 0 auto;
  }
  input ~ .checkmark {
    background-color: $color_white;
    &:after {
      content: "";
      position: absolute;
      width: 9px;
      height: 9px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      background: transparent;
      border-radius: 50%;
      transition: all 0.3s linear;
    }
  }
  input:checked ~ .checkmark {
    background-color: $color_white;
    &:after {
      content: "";
      position: absolute;
      width: 9px;
      height: 9px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1);
      background: $color_dark_grey_2;
      border-radius: 50%;
    }
  }
  input:checked ~ .checkmark_brown {
    background-color: $color_white;
    &:after {
      content: "";
      position: absolute;
      width: 9px;
      height: 9px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: $color_brown;
      border-radius: 50%;
    }
  }
}

// Radio input for volume select
.radio-volume {
  position: relative;
  input[type="radio"] {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
    visibility: hidden;
    &:focus {
      & + label {
        border: 1px solid $color_dark_grey_2;
      }
    }
  }
  input + label {
    @include custom-font-inter(400, 12px, normal);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 51px;
    height: 50px;
    margin-right: 9px;
    border: 1px solid $color_grey_light;
    background-color: $color_white;
    transition: border 0.2s;
    cursor: pointer;
    user-select: none;
    &:hover {
      border-color: $color_dark_grey_2;
    }
  }
  input:checked + label {
    border-color: $color_dark_grey_2;
  }
}

// Input with grey border and label
// <fieldset class="input-grey">
// <input type="text">
// <legend>LABEL</legend>
// </fieldset>
.input-grey {
  border: none;
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  &::after {
    color: $color_red;
    display: none;
    content: "";
    margin-top: 5px;
    @include custom-font-inter(300, 12px, 14px);
  }
  input {
    padding: 22px 18px 22px 30px;
    border: 1px solid $color_grey_light;
    color: $color_dark_grey_2;
    @include custom-font-inter(700, 14px, 18px);
    transition: border 0.2s;
    height: 60px;

    @media (max-width: $phone_max) {
      height: 50px;
      padding-left: 20px;
      padding-right: 20px;
    }
    &:hover,
    &:focus {
      border-color: $color_dark_grey_2;
      & + legend {
        color: $color_dark_grey_2;
      }
    }
  }
  legend {
    position: absolute;
    top: 0;
    left: 15px;
    margin: 0;
    transform: translateY(-50%);
    padding: 0 10px;
    background-color: $color_white;
    color: $color_grey_light;
    transition: color 0.2s;
  }

  &.error {
    input {
      border: 1px solid $color_red;
    }
    &:after {
      display: block;
      content: "Це поле обов`язкове для заповнення";
    }
  }
  &.error-ru {
    input {
      border: 1px solid $color_red;
    }
    &:after {
      display: block;
      content: "Это поле обязательно для заполнения";
    }
  }

  &.phone-error {
    input {
      border: 1px solid $color_red;
    }
    &:after {
      display: block;
      content: "Невірний формат номеру";
    }
  }
  &.phone-error-ru {
    input {
      border: 1px solid $color_red;
    }
    &:after {
      display: block;
      content: "Неверный формат номера";
    }
  }

  &.email-error {
    input {
      border: 1px solid $color_red;
    }
    &:after {
      display: block;
      content: "Невірний формат пошти";
    }
  }
  &.email-error-ru {
    input {
      border: 1px solid $color_red;
    }
    &:after {
      display: block;
      content: "Неверный формат почты";
    }
  }
}
// Input default
.input-default {
  background-color: $color_white_smoke;
  height: 50px;
  width: 100%;
  padding: 16px 20px;
  @include custom-font-inter(400, 14px, normal);
  color: $color_dark_grey_2;
  border: 1px solid transparent;
  &::placeholder {
    color: $color_grey_4;
  }
  &:hover,
  &:focus {
    border-color: $color_dark_grey_2;
  }
}

// Checkbox
// <label class='checkbox-default'>
// <input type="checkbox">
// <span class='checkmark'></span>
// <span>LABEL</span>
// </label>
.checkbox-default {
  display: flex;
  cursor: pointer;
  input[type="checkbox"] {
    position: absolute;
    height: 0;
    width: 0;
    visibility: hidden;
    &:checked + .checkmark::before {
      background: url("../../images/icons/check-black.svg") no-repeat center
        $color_white_smoke;
    }
  }
  .checkmark {
    &::before {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      background-color: $color_white_smoke;
      border-radius: 5px;
      transform: translateY(-2px);
      cursor: pointer;
    }
  }
  .text {
    @include custom-font-inter(400, 12px, normal);
    padding-left: 10px;
  }
}

// User content

.user-content {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
  color: $color_grey;
  p {
    @include body__text;
    margin-bottom: 5px;
  }
  // span {
  //   font-weight: 300;
  // }
  ul {
    margin-left: 18px;
  }
  ul,
  ol {
    margin-top: 10px;

    // @media (max-width: $phone-max) {
    //   margin-top: 30px;
    // }
  }
  ol {
    li {
      @include body__text;
      color: $color_dark_grey_2;
      // padding-left: 12px;
      span {
        color: $color_grey;
        @include body__text;
      }
    }
    & > li > ol {
      margin-left: 0;
    }
    li {
      &::marker {
        margin-right: 20px;
      }
    }
  }

  ol {
    counter-reset: item;
    > li {
      list-style: none;
      counter-increment: item;
      &:before {
        content: counters(item, ".") ". ";
        font-weight: 700;
        margin-right: 10px;
      }
      & + li {
        margin-top: 20px;
      }
      // 2nd
      > ol {
        counter-reset: item;
        > li {
          list-style: none;
          counter-increment: item;
          &:before {
            content: counters(item, ".") ". ";
            font-weight: 300;
          }
          & + li {
            margin-top: 15px;
          }
          // 3rd
          > ol {
            counter-reset: item;
            > li {
              list-style: none;
              counter-increment: item;
              &:before {
                content: counters(item, ".") ". ";
                font-weight: 300;
              }
            }
          }
        }
      }
      p {
        display: inline;
      }
    }
  }

  ul {
    margin-left: 0;
    li {
      position: relative;
      @include body__text;
      padding-left: 18px;
      color: $color_dark_grey_2;
      &:before {
        display: block;
        content: "";
        width: 3px;
        height: 3px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background-color: $color_dark_grey_2;
      }
    }
  }
  li {
    margin-bottom: 15px;
  }
  h2 {
    line-height: 120%;
    margin: 1.2em 0 0.6em 0;
  }
  h3 {
    margin: 1.5em 0 1.2em 0;
  }
  h4 {
    letter-spacing: 0.02em;
    margin: 3.3em 0 1.7em 0;
  }
  h6 {
    font-weight: 700;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:first-child {
      margin-bottom: 10px;
    }
  }
  img,
  video {
    margin: 15px;
  }
  blockquote {
    @include h3;
    text-align: center;
    color: $color_dark_grey_2;
    padding: 60px 0;
    margin: 80px 0;
    border-top: 1px solid $color_grey_3;
    border-bottom: 1px solid $color_grey_3;
    position: relative;
    @media (max-width: $phone_max) {
      margin: 30px 0;
      padding: 25px 0;
    }
    &:before {
      content: "";
      position: absolute;
      top: -10px;
      left: 49.6%;
      transform: translateX(-49.6%);
      width: 30px;
      height: 30px;
      padding-left: 32px;
      padding-right: 32px;
      background: url("/src/images/icons/quotes.svg") no-repeat center
        $color_white;
      @media (max-width: $phone_max) {
        width: 19px;
        height: 19px;
        padding-left: 23px;
        padding-right: 23px;
      }
    }
    &:after {
      content: "";
      padding-left: 32px;
      padding-right: 32px;
      position: absolute;
      bottom: -20px;
      left: 49.4%;
      width: 30px;
      height: 30px;
      // background: $color_white;
      // @include txt__aroma-icon;
      transform: translateX(-49.4%);
      background: url("/src/images/icons/quotes_reverse.svg") no-repeat center
        $color_white;
      @media (max-width: $phone_max) {
        width: 19px;
        height: 19px;
        bottom: -12px;
        padding-left: 23px;
        padding-right: 23px;
      }
    }
  }

  .info-table {
    padding-top: 80px;
    padding-bottom: 80px;
    margin-top: 100px;
    &__wrap {
      overflow-x: auto;
    }
  }
  table {
    width: 100%;
    min-width: 1201px;
    ol,
    ul {
      margin-top: 10px;
      li {
        margin-bottom: 5px;
      }
    }
    ol {
      list-style: decimal;
      // counter-reset: item;
      li {
        color: $color_grey;
        span {
          color: $color_black;
        }
      }
    }
    p {
      @include custom-font-inter(400, 12px, 15px);
      color: $color_dark_grey_2;
      margin-top: 12px;
    }
    .check {
      display: inline-block;
      width: 5px;
      height: 5px;
      background: $color_grey;
      border-radius: 50%;
    }
  }
  thead {
    h4 {
      margin: 0;
    }
  }
  tbody * {
    @include custom-font-inter(700, 14px, 18px);
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: $color_black;
  }
  th {
    p:first-child {
      @include custom-font-inter(300, 18px, 28px);
      letter-spacing: 0.02em;
      color: $color_black;

      margin: 0;
    }
  }
  tr {
    border-bottom: 1px solid rgba($color_dark_grey, 0.5);
    td {
      text-align: center;
      vertical-align: middle;
    }
    td:first-child {
      width: 382px;
      text-align: left;
    }
  }
  td,
  th {
    padding: 15px 0 15px 0;
  }

  b {
    font-weight: 700;
  }
  em {
    font-style: italic;
    font-weight: 400;
  }
  .w-50 {
    width: 50%;
  }

  @media (max-width: $phone-max) {
    width: 100%;
    height: auto;
  }
}

// Breadcrumbs

.breadcrumbs {
  display: flex;
  align-items: center;
  gap: 23px;
  margin-top: 20px;

  @media (max-width: $tablet_max) {
    margin-top: 10px;
  }
  @media (max-width: $phone-max) {
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  li {
    position: relative;
    @include txt__small-addition;
    white-space: nowrap;
    color: $color_dark_grey_2;
    &:first-child {
      margin-left: 0;
      &:before {
        display: none;
      }
    }
    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 3px;
      height: 3px;
      top: 50%;
      left: -13px;
      transform: translateY(-50%);
      border-radius: 50%;
      background-color: $color_dark_grey_2;
    }

    &:last-child,
    &:last-child * {
      color: $color_grey_2;

      &::before {
        background-color: $color_grey_2;
      }
    }
  }
}

// Search
.aroma-small-search {
  width: 100%;
  position: relative;
  input {
    width: 100%;
    border: 0;
    padding: 4.5px 25px 4.5px 8px;
    border-radius: 4px;
    color: $color_grey;
    &::placeholder {
      color: $color_dark_grey;
    }
  }
  &__srch-btn {
    position: absolute;
    top: 7px;
    right: 10px;
    cursor: pointer;
    &:hover {
      svg {
        fill: $color_brown;
      }
    }
  }
}

//Advice
.advice {
  display: flex;
  position: fixed;
  width: 60px;
  height: 60px;
  right: 20px;
  bottom: 95px;
  background: $color_grey_light url("../../images/icons/dialog_grey.svg")
    no-repeat center;
  border-radius: 50%;
  transition: all 0.1s linear;
  z-index: 2;
  cursor: pointer;
  &:hover {
    background-color: #ece0d5;
  }
  &.active {
    background: $color_gold url("../../images/icons/close_gold.svg") no-repeat
      center;
    &:hover {
      background-color: transparent;
    }
    ul {
      opacity: 1;
      z-index: 1;
      visibility: visible;
    }
  }
  @media (max-width: $phone-max) {
    width: 50px;
    height: 50px;
  }
  ul {
    display: flex;
    flex-direction: column;
    top: -195px;
    position: absolute;
    transform: translateY(0%);
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    transition: all 0.3s ease-in;
    li {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin-bottom: 5px;
      position: relative;
      background: $color_gold;
      &:hover {
        background-color: #ece0d5;
      }

      a {
        display: inline-block;
        width: 100%;
        height: 100%;
      }

      @media (max-width: $phone-max) {
        width: 50px;
        height: 50px;
      }
    }
  }
  &__messenger,
  &__viber,
  &__google {
    &:before {
      content: "";
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      background: url("../../images/icons/helper-social-small-sprite.svg")
        no-repeat center;
      transform: translate(-50%, -50%);
      width: 16px;
      height: 18px;
      background-size: 16px;
      background-position: 0 0;
    }
  }
  &__viber {
    &:before {
      background: url("../../images/icons/helper-social-small-sprite.svg")
        no-repeat center;
      background-position: 0 -36px;
      background-size: 16px;
    }
  }
  &__google {
    &:before {
      background: url("../../images/icons/helper-social-small-sprite.svg")
        no-repeat center;
      background-position: 0 -16px;
      background-size: 16px;
    }
  }
}

//toggle-slide-item

.toggle-slide-item {
  position: relative;
  border-top: 1px solid $color_grey_light;
  // border-bottom: 1px solid $color_dark_grey;
  // &:not(:first-child) {
  //   margin-top: -1px;
  // }
  &__title {
    color: $color_dark_grey_2;
    cursor: pointer;
    padding: 22px 25px 19px;
    position: relative;
    text-transform: uppercase;
    font-weight: 700;

    .arrow {
      display: block;
      position: absolute;
      width: 15px;
      height: 18px;
      top: 50%;
      left: 0;
      background: url("../../images/icons/expand_collapse.svg") no-repeat center;
      background-size: cover;
      transform: translateY(-50%) rotate(0deg);
      transition: 0.3s all linear;
    }
  }
  &__content {
    display: none;
    padding-bottom: 25px;
    padding-top: 25px;
    // border-bottom: 1px solid $color_dark_grey;
  }
  &.active {
    border-top-color: $color_dark_grey_2;
    .toggle-slide-item__content {
      display: block;
    }
    .toggle-slide-item__title {
      color: $color_dark_grey_2;
    }
    .arrow {
      transform: translateY(-50%) rotate(180deg);
    }
  }
  &:last-child {
    border-bottom: 1px solid $color_dark_grey;
  }
}

// choosed label
.choosed-label {
  display: inline-flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 100px;
  background: $color_grey_3;
  border: 1px solid $color_grey_3;
  font-size: 12px;
  font-weight: 400;
  transition: all 0.3s linear;
  &_transparent {
    background: transparent;
  }
  &:hover {
    border: 1px solid $color_grey_2;
  }
  &__txt {
    margin-right: 10px;
  }
  &__ico {
    font-size: 9px;
    color: $color_grey_2;
    transition: 0.3s all linear;
    cursor: pointer;
    &:hover {
      color: $color_black;
    }
  }
}

// Brand itemsquare
.brand-item {
  &__img-wrap {
    @include flex-center;
    width: 210px;
    height: 210px;
    border: 1px solid $color_grey_2;
    padding: 20px;
    transition: all 0.3s linear;
    @media (max-width: $phone-max) {
      width: 150px;
      height: 150px;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
  &__brand-name {
    display: inline-flex;
    @include txt__roboto;
    text-transform: uppercase;
    font-weight: 700;
    color: $color_grey;
    margin-top: 20px;
    &:hover {
      color: $color_brown;
    }
  }
  &:hover {
    .brand-item__img-wrap {
      border: 1px solid $color_brown;
      cursor: pointer;
    }
  }
}

/* ------------------------------ Video wrapper ----------------------------- */
.video-wrapper {
  position: relative;
  // padding-top: 64%;
  width: 100%;
  height: 390px;
  overflow: hidden;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
  &__play-btn {
    position: absolute;
    width: 80px;
    height: 80px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url("../../images/icons/play_btn.svg") no-repeat center;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s linear;
    &:hover {
      transform: translate(-50%, -50%) scale(0.95);
    }
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @media (max-width: $tablet-max) {
    height: 426px;
  }
  @media (max-width: $phone-max) {
    height: 204px;
  }
}

.aroma-ico-arrow_left-long {
  font-size: 40px;
  margin: 8px 0;
  color: $color_dark_grey_2;
}

.divider-horizontal {
  width: 100%;
  height: 1px;
  background-color: $color_grey_light;
}

// Products-line block
.products-line {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    row-gap: 40px;
    .product-card {
      .product-page__details-price {
        font-size: inherit;
        line-height: inherit;
      }
    }
    @media (max-width: $tablet_max) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: $phone_max) {
      row-gap: 20px;
    }
  }
}

// product-card

.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: start;
  text-align: center;
  width: 325px;
  background: $color_white;
  transition: box-shadow 0.3s ease;

  &:active {
    box-shadow: 0px 8px 20px 0px #74747424;
  }

  &__small {
    display: flex;
    justify-content: flex-start;
    gap: 22px;

    &-img {
      position: relative;
      @include flex-center;
      width: 208px;
      height: 180px;
      border: 1px solid #d7d5cf;

      a {
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &-content {
      text-align: start;
      h5 {
        letter-spacing: 0.03em;
        color: $color_dark_grey_2;
        text-transform: uppercase;
        margin-bottom: 2px;
      }
    }

    &-text {
      @include body__text;
      color: $color_dark_grey_2;
    }

    .product-card__price {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 8px;

      &-current {
        color: $color_dark_grey_2;

        span {
          display: inline-block;
          @include body__text;
        }

        .txt__small-body {
          font-size: 14px;
          text-align: center;
          color: #7d7d7d;
          margin-right: 4px;
        }
      }

      &-old {
        @include custom-font-inter(300, 14px, 17px);
        color: #7d7d7d;
        text-decoration: line-through;
      }
    }

    @media (max-width: $phone-max) {
      gap: 20px;

      &-img {
        width: 108px;
        height: 92px;
      }

      &-content {
        h5 {
          @include h6;
        }
      }

      &-text {
        @include txt__small-addition;
      }
      .product-card__price {
        &-current {
          span {
            line-height: 23px;
          }
          span:first-of-type {
            font-weight: 300;
            font-size: 12px;
          }
        }
      }
    }
  }

  &__not-available {
    position: relative;

    .product-card__info-hidden::after,
    &::before {
      display: block;
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-color: rgba(255, 255, 255, 0.5);
      filter: blur(5px);
      pointer-events: none;
    }

    .product-card__image-wrapper {
      &-heart {
        z-index: 2;
      }
    }
  }

  &-hot {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 32px;
      height: 32px;
      z-index: 2;

      background: url("../../images/icons/badge.svg") no-repeat center;
      background-size: contain;

      @media (max-width: $phone_max) {
        width: 28px;
        height: 28px;
      }
    }
  }

  &__image-wrapper {
    position: relative;
    width: 100%;
    height: 366px;
    padding: 0;
    margin-bottom: 16px;

    a {
      @include flex-center;
      height: 100%;
      width: 100%;

      img,
      source {
        max-width: 100%;
        max-height: 100%;
      }
    }

    &-heart {
      position: absolute;
      display: block;
      top: 16px;
      right: 16px;
      width: 24px;
      height: 24px;
      &::before,
      &::after {
        position: absolute;
        display: block;
        content: "";
        width: 40px;
        height: 20px;
        background-image: url("../../images/icons/wishlist-black.svg");
        opacity: 0;
      }
      &::before {
        top: -8px;
        left: -8px;
      }
      &::after {
        bottom: -8px;
        right: -8px;
      }
      &.active {
        width: 22.5px;
        height: 20px;
        background: url("../../images/icons/wishlist-black.svg") no-repeat
          center;
        background-size: contain;
        animation: heartbeat 0.8s ease;

        @media (max-width: $phone-max) {
          width: 19px;
          height: 17px;
        }
      }
      &.loading {
        animation: heartbeat 0.8s ease infinite;
      }
    }
  }

  &__brand {
    @include custom-font-inter(700, 16px, 21px);
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: $color_dark_grey_2;
    margin-bottom: 4px;
  }

  &__info {
    position: relative;
    width: 100%;

    &-visible {
      .txt__body,
      p {
        max-width: 325px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .txt__body {
        display: flex;
        justify-content: center;
        gap: 4.5px;
        color: #7d7d7d;
        span:first-of-type {
          display: inline-block;
          max-width: 325px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .visible {
          display: inline;
          text-overflow: initial;
        }
      }
      p {
        @include custom-font-inter(400, 16px, 23px);
        margin-bottom: 4px;
      }
    }

    &-visible,
    &-hidden {
      text-align: center;
    }

    &-hidden {
      display: none;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      box-shadow: 0px 12px 20px 0px #74747424;
      background-color: $color_white;

      .txt__body {
        color: #7d7d7d;
      }

      p {
        @include custom-font-inter(400, 16px, 23px);
        max-width: 325px;
        margin-bottom: 4px;
        text-align: center;
      }
    }
  }

  .product-card__price {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    margin: 8px 0 16px;

    &-current {
      color: $color_dark_grey_2;
      font-size: 16px;
      font-weight: 300;
      span {
        display: inline-block;
        @include custom-font-inter(300, 16px, 23px);
      }

      .txt__small-body {
        font-size: 14px;
        text-align: center;
        color: #7d7d7d;
        margin-right: 4px;
      }
    }

    &-old {
      @include custom-font-inter(300, 14px, 17px);
      color: #7d7d7d;
      text-decoration: line-through;
    }
  }

  @media (min-width: $desktop_min) {
    &:hover {
      box-shadow: 0px 8px 20px 0px #74747424;
      z-index: 2;

      .product-card__info-hidden {
        display: block;
      }
    }
  }

  @media (max-width: $phone_max) {
    width: 150px;

    &__image-wrapper {
      width: 150px;
      height: 180px;

      margin-bottom: 8px;

      &-heart {
        top: 8px;
        right: 8px;
        width: 20px;
        height: 20px;

        svg {
          width: 19px;
          height: 17px;
        }
      }
    }

    &__brand {
      font-size: 12px;
      line-height: 16px;
    }

    &__info {
      &-visible,
      &-hidden {
        .txt__body,
        p {
          max-width: 150px;
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
    .product-card__price {
      margin: 8px 0;

      &-current {
        span {
          line-height: 23px;
        }
        span:first-of-type {
          font-weight: 300;
          font-size: 12px;
        }
      }
    }
  }
}

.error-text {
  color: $color_red;
  @include custom-font-inter(400, 12px, normal);
  opacity: 0.7;
}

.scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}
