.header-container {
    &[data-is-fixed-on-desktop="true"] {
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background: #fff;
        z-index: 10;

        @media (max-width: $tablet-max) {
            z-index: 4;
        }
    }
}

.header-spacer {
    opacity: 0;
    overflow: hidden;
    visibility: hidden;
    min-height: 109px;
    @media (max-width: $tablet_max) {
        min-height: 80px;
    }
    @media (max-width: $phone_max) {
        min-height: 57px;
    }

    &.header-spacer__info {
        min-height: 130px;

        @media (max-width: $tablet_max) {
            min-height: 99px;
        }
        @media (max-width: $phone_max) {
            min-height: 76px;
        }
    }
}

.header-wrapper {
    max-width: 1920px;
    padding: 0 30px;
    margin: 0 auto;

    @media (max-width: $tablet_max) {
        padding: 0 20px;
    }
}

.aroma-header {
    position: relative;
    display: flex;
    border-bottom: 1px solid $color_header_border;

    &__logo {
        margin: 28px 30px 22px 0;

        &-img {
            width: 110px;
            height: 54px;
        }

        @media (max-width: $tablet-max) {
            margin: 20px 20px 15.5px 0;

            &-img {
                width: 82px;
                height: 40px;
            }
        }

        @media (max-width: $phone_max) {
            position: absolute;
            top: 13px;
            left: 50%;
            transform: translateX(-50%);
            margin: 0;

            &-img {
                width: 67px;
                height: 33px;
            }
        }
    }

    &__right-side {
        width: 100%;
        border-left: 1px solid $color_header_border;

        @media (max-width: $phone-max) {
            border-left: none;
        }
    }

    &__row {
        &:nth-child(1) {
            border-bottom: 1px solid $color_header_border;
        }

        @media (max-width: $tablet-max) {
            padding: 13px 0 14px 20px;
            &:nth-child(1) {
                border-bottom: none;
            }
            &.aroma-header__top {
                height: 100%;
            }
            &.aroma-header__bottom {
                display: none;
            }
        }

        @media (max-width: $phone_max) {
            padding: 0;
        }
    }

    &__bottom {
        padding: 0 0 0 30px;
        display: flex;
        align-items: center;
    }

    &__top {
        display: flex;
        align-items: center;
        padding: 20px 10px 20px 30px;
    }

    &__top-right,
    &__top-left {
        display: flex;
        align-items: center;
    }

    &__top-left {
        flex: 1 1;
        gap: 33px;

        @media (max-width: $phone-max) {
            display: none;
        }
    }

    &__top-right {
        padding: 1px 0;
        gap: 24px;

        @media (max-width: $tablet-max) {
            justify-content: flex-end;
            gap: 16px;
        }

        @media (max-width: $phone-max) {
            justify-content: flex-start;
            width: 100%;
            margin: 15px 0;
            gap: 0;
        }
    }

    &__top-left-links {
        position: relative;
        display: flex;
        gap: 36px;
        padding-right: 28px;

        &::after {
            position: absolute;
            content: "";
            display: block;
            top: -5px;
            right: 0;
            width: 1px;
            height: 26px;
            background-color: $color_grey_light;
        }

        @media (max-width: $tablet-max) {
            display: none;
        }
    }

    &__phone {
        color: $color_dark_grey_2;
        cursor: pointer;

        a {
            @include custom-font-inter(700, 12px, 15px);
            letter-spacing: 0.03em;
        }
    }

    // login
    &__login {
        position: relative;
        cursor: pointer;
        width: 24px;
        height: 24px;

        &-logout {
            span {
                @include h6;
                letter-spacing: 0.03em;
                color: $color_red;
                text-transform: uppercase;
            }
        }

        @media (max-width: $phone-max) {
            margin-left: 38px;
        }
    }

    &__user-online {
        display: none;
    }

    // wishist and cart
    &__wishlist-and-cart {
        position: relative;
        padding: 0 24px;
        display: flex;
        align-items: center;
        gap: 16px;

        &::after,
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            width: 1px;
            height: 26px;
            background-color: $color_grey_light;
        }

        &::after {
            right: 0;
        }

        &::before {
            left: 0;
        }

        .wishlist,
        .cart {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;

            &:hover {
                cursor: pointer;
            }

            .wishlist-btn,
            .cart-btn {
                position: relative;
                width: 21px;
                height: 18px;

                .amount {
                    position: absolute;
                    display: block;
                    border-radius: 100px;
                    text-align: center;
                    box-sizing: content-box;
                    color: $color_white;
                }
            }

            .cart-btn {
                top: -2px;

                .amount {
                    right: -8px;
                    bottom: -8px;
                    padding: 2px;
                    min-width: 10px;
                    background: $color_red_cart_bg;

                    &__txt {
                        font-family: $inter;
                        font-size: 9px;
                        font-weight: 600;
                        line-height: normal;
                    }
                }
            }

            .wishlist-btn {
                .amount {
                    top: -7px;
                    left: 85%;
                    padding: 5px;
                    min-width: 15px;
                    background: $color_gold;
                }
            }
        }

        @media (max-width: $tablet-max) {
            padding: 0 16px;
        }

        @media (max-width: $phone-max) {
            position: absolute;
            right: 0;
            gap: 14px;
            padding: 0;

            &::after,
            &::before {
                display: none;
            }

            .wishlist .wishlist-btn .amount {
                border: 0;
                font-size: 8px;
                padding: 1px;
                left: 50%;
            }
        }
    }

    //search-btn-mob
    &__search-btn-mob {
        display: none;
        @media (max-width: $tablet-max) {
            display: block;
            height: 20px;
            width: 20px;
        }

        @media (max-width: $phone-max) {
            margin-left: 14px;
        }
    }

    // Language changer
    &__language-changer {
        a,
        span {
            @include custom-font-inter(700, 14px, 18px);
            letter-spacing: 0.03em;
            text-transform: uppercase;
            color: $color_tide;

            &:hover {
                color: $color_dark_grey_2;
            }

            &:last-child {
                margin-left: 12px;
            }

            &.active {
                color: $color_dark_grey_2;
            }
        }

        @media (max-width: $tablet_max) {
            display: none;
        }
    }

    &__phone-and-lang-changer {
        display: none;

        @media (max-width: $tablet-max) {
            display: flex;
        }
    }

    // Navigation
    &__nav {
        flex: 1;

        &-links {
            display: none;
        }

        @media (max-width: $tablet-max) {
            position: absolute;
            top: 0;
            left: 0;
            width: 358px;
            background: $color_white;
            overflow: auto;
            height: 100vh;
            transform: translateX(-100%);
            transition: all 0.3s;

            &.active {
                transform: translateX(0);
            }
            &.width-100 {
                width: 100%;
                transform: translateX(0);

                @keyframes moveX {
                    0%,
                    100% {
                        transform: translateX(0);
                    }
                    50% {
                        transform: translateX(-100vw);
                    }
                    50.1% {
                        transform: translateX(100vw);
                    }
                }

                animation: moveX 0.4s ease;
            }

            &-links {
                display: flex;
                flex-direction: column;
                padding: 0 20px;

                .aroma-header__top-left-link {
                    display: inline-block;
                    padding: 15px 0 13px;
                    border-bottom: 1px solid $color_grey_3;

                    &:first-child {
                        border-top: 1px solid $color_grey_3;
                    }

                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }

        @media (max-width: $phone-max) {
            width: 298px;
        }
    }

    &__nav-list {
        display: flex;
        gap: 30px;

        @media (min-width: 1301px) and (max-width: 1404px) {
            gap: 14px;
        }

        @media (min-width: 1201px) and (max-width: 1300px) {
            gap: 10px;
        }

        @media (max-width: $tablet-max) {
            flex-direction: column;
            gap: 15px;
            padding: 0 20px;
        }
    }

    &__nav-item {
        .aroma-header__top-left-link {
            display: none;

            @media (max-width: $tablet_max) {
                display: inline-flex;
                padding-bottom: 15px;
            }
        }

        &:last-child {
            padding: 0;
            border: none;
        }

        .category-columns {
            display: flex;
            flex-direction: row;
            gap: 78px;

            @media (max-width: $phone-max) {
                flex-direction: column;
                gap: 40px;
            }
        }

        .category-column {
            width: 267px;

            &__title {
                @include custom-font-inter(700, 12px, 15px);
                color: $color_black;
                text-transform: uppercase;
            }

            &__list {
                display: flex;
                flex-direction: column;
                gap: 10px;

                margin-top: 12px;
                transition: max-height 0.3s ease;

                &.empty-list {
                    display: none;
                }

                @media (max-width: $phone-max) {
                    gap: 16px;
                }

                .category-column__link_star {
                    position: relative;
                    display: flex;
                    align-items: center;
                    align-content: center;

                    &:after {
                        content: "";
                        display: block;
                        width: 16px;
                        height: 16px;
                        position: relative;
                        background: url("../../images/icons/black_star.svg") no-repeat;
                    }
                }
            }

            &__link {
                @include custom-font-inter(400, 12px, 15px);
            }

            @media (max-width: $phone-max) {
                &__link {
                    font-size: 14px;
                    line-height: 17px;
                }
                &.active {
                    .category-column__title {
                        &-arrow {
                            transform: rotate(-180deg);
                        }
                    }

                    .category-column__list {
                        max-height: 1000px;
                        gap: 16px;
                        padding: 0 20px 20px 42px;
                    }
                }
            }
        }

        .promotions {
            display: flex;
            gap: 79px;
            margin-top: 7px;

            &__column {
                width: 209px;
            }

            &__list {
                display: flex;
                flex-direction: column;
                gap: 10px;

                margin-top: 24px;
            }

            @media (max-width: $tablet-max) {
                margin: 0;
                gap: 140px;
            }

            @media (max-width: $phone-max) {
                flex-direction: column;
                gap: 24px;

                margin-top: 0;

                &__column {
                    width: 100%;
                }

                &__list {
                    gap: 16px;
                }

                .category-column__title {
                    @include custom-font-inter(400, 12px, 15px);
                    text-transform: none;
                }

                .category-column__link {
                    @include h6;
                    text-transform: uppercase;
                    letter-spacing: 0.03em;
                }
            }
        }

        @media (max-width: $tablet-max) {
            border-bottom: 1px solid $color_grey_3;

            &:first-child {
                border-top: 1px solid $color_grey_3;
                padding-top: 15px;
            }
        }

        @media (max-width: $phone-max) {
            position: relative;
            &:not(:first-child) {
                padding-left: 24px;

                &::before {
                    position: absolute;
                    left: 0;
                    top: 5px;
                    content: "";
                    display: block;
                    width: 15px;
                    height: 8px;
                    background: url("../../images/icons/expand_collapse.svg") no-repeat center;
                    transition: transform 0.3s ease-in-out;
                }
            }

            &.active {
                &:not(:first-child) {
                    &::before {
                        transform: rotate(-180deg);
                    }
                }

                .aroma-header__nav-item-drop {
                    max-height: fit-content;
                    padding-bottom: 17px;
                    height: fit-content;
                }
            }
        }
    }

    &__nav-link {
        position: relative;
        display: inline-flex;
        text-transform: uppercase;
        padding-top: 13px;
        padding-bottom: 14px;
        width: 100%;

        @include custom-font-inter(700, 12px, 16px);
        letter-spacing: 0.03em;
        color: $color_dark_grey_2;
        cursor: pointer;

        &.active {
            color: $color_dark_grey_2;

            &:after {
                content: "";
                position: absolute;
                width: 100%;
                bottom: -1px;
                left: 0;
                height: 2px;
                transition: background 0.3s ease;
                background: $color_dark_grey_2;
            }
        }

        &_stocks {
            color: $color_red;

            &:hover,
            &:active {
                color: $color_red;
            }

            &:before {
                content: "\e928";
                @include txt__aroma-icon;
                position: relative;
                display: block;
                margin-right: 5px;
                font-size: 10px;
            }
        }

        &_present {
            color: $color_dark_gold;

            &:before {
                content: "\e921";
                @include txt__aroma-icon;
                position: relative;
                display: block;
                left: -5px;
            }
        }

        @media (min-width: $desktop-min) {
            &:hover {
                color: $color_dark_grey_2;

                &:after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    bottom: -1px;
                    left: 0;
                    height: 2px;
                    transition: background 0.3s ease;
                    background: $color_dark_grey_2;
                    z-index: 5;
                }
            }
        }

        @media (max-width: $tablet-max) {
            padding-bottom: 13px;
            padding-top: 0px;
            font-size: 14px;
            line-height: 18px;
        }
    }

    &__overlay {
        display: none;
        position: fixed;
        top: calc(100% + 1px);
        left: 0;
        bottom: 0;
        right: 0;
        height: 100vh;
        opacity: 70%;
        background: $color_dark_grey_2;

        &.active {
            display: block;
            z-index: 3;
        }
    }

    &__mn-close {
        @media (max-width: $phone-max) {
            display: block;
            position: absolute;
            top: 20px;
            right: 23px;
            font-size: 15px;
            color: $color_white;
        }
    }

    // navigation drop down

    &__nav-item-drop {
        display: none;
        position: absolute;
        top: calc(100% + 0.5px);
        left: 0;
        width: 100%;
        max-height: calc(100vh - 110px);
        height: auto;
        padding: 24px 40px;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.4s linear;

        &.active {
            display: flex;
            opacity: 1;
            visibility: visible;
            z-index: 4;
        }

        &::after,
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            background: $color_white_smoke;
            width: 1002px;
            height: 100%;

            @media (max-width: $tablet-max) {
                display: none;
            }
        }

        &::after {
            right: -999px;
        }

        &::before {
            left: -999px;
        }

        @media (max-width: $tablet_max) {
            top: 0;
            overflow: auto;
            flex-direction: column;
            max-height: 100vh;
            height: 100%;
            width: 100%;
            padding: 0;
        }

        @media (max-width: $phone-max) {
            position: static;
            display: flex;
            max-height: 0;
            opacity: 1;
            visibility: visible;
            background: $color_white;
        }
    }

    &__drop-left-content {
        display: flex;
        padding: 22px 0 60px;
        width: 100%;
        overflow: auto;

        @media (max-width: $tablet-max) {
            padding: 46px 40px;
            order: 2;
            height: initial;
            flex: 0 0 auto;

            &:before {
                display: none;
            }

            .category-column {
                max-width: 298px;
                padding-right: 0;
            }

            .category-columns {
                max-width: 664px;
                flex-wrap: wrap;
            }
        }

        @media (max-width: $phone-max) {
            flex-direction: column;
            padding: 30px 0 0;

            .category-column {
                max-width: 100%;
            }
        }

        .category-columns {
            .category-column-3 {
                @media (min-width: $tablet-min) {
                    width: 284px;
                }
            }
        }

        &_two-col {
            max-width: 653px;

            .category-columns {
                flex-direction: row;
                width: 100%;
            }

            @media (max-width: $tablet-max) {
                order: 0;
                padding: 53px 40px 63px;
                max-width: 100%;
            }

            @media (max-width: $phone_max) {
                order: 0;
                padding: 20px 20px 40px;
            }
        }
    }

    &__drop-right-content {
        display: flex;
        height: 100%;
        padding: 46px 40px 43px 57px;
        background: $color_white;

        @media (max-width: $tablet-max) {
            padding: 46px 46px 32px 58px;

            .category-alpfabet {
                max-width: 100%;

                &__content {
                    margin-bottom: 30px;
                }

                &__list {
                    gap: 1px;
                }

                &__btn {
                    height: 50px;
                }
            }

            .image-columns .info-block-type-1 {
                margin-bottom: 20px;
            }
        }

        @media (max-width: $phone-max) {
            border-top: 1px solid $color_white_smoke;
            order: 1;
            height: initial;
            flex: 0 0 auto;
            // padding: 20px 20px 34px 20px;
            padding: 0;
            flex-direction: column;

            &:before {
                display: none;
            }
        }

        .category-alpfabet {
            max-width: 268px;

            &__title {
                @include txt__small-addition;
                color: $color_black;
                display: inline-block;
                margin-bottom: 24px;
            }

            &__content {
                border-top: 1px solid $color_white_smoke;
                margin-bottom: 90px;

                @media (max-width: $phone-max) {
                    margin-bottom: 30px;
                }
            }

            &__list {
                display: flex;
                flex-wrap: wrap;

                @media (max-width: $phone-max) {
                    gap: 0;
                }
            }

            &__item {
                @include flex-center;
                position: relative;

                width: 43px;
                height: 43px;

                border-bottom: 1px solid $color_white_smoke;

                &::after {
                    content: "";
                    position: absolute;
                    top: 11px;
                    right: -1px;
                    height: 20px;
                    width: 1px;
                    background-color: $color_white_smoke;
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: 11px;
                    left: -1px;
                    height: 20px;
                    width: 1px;
                    background-color: $color_white_smoke;
                }
            }

            &__link {
                @include custom-font-inter(700, 14px, 18px);
                letter-spacing: 0.03em;
                color: $color_black;
                text-transform: uppercase;
                text-align: center;

                width: 100%;
                padding: 12px 0;
            }

            &__btn {
                @include flex-center;
                width: 268px;
                height: 60px;
                border: 1px solid $color_dark_grey_2;

                span {
                    @include h6;
                    text-transform: uppercase;
                    letter-spacing: 0.03em;
                    color: $color_dark_grey_2;
                }

                @media (min-width: $desktop-min) {
                    &:hover {
                        background-color: $color_black;

                        span {
                            color: $color_white;
                        }
                    }
                }

                @media (max-width: $phone-max) {
                    width: 230px;
                    height: 50px;
                }
            }
        }
    }

    &__drop-right-images {
        max-width: 787px;
        height: 100%;
        background: $color_white;

        padding: 51px 40px 60px 77px;

        .image-columns {
            display: flex;
            flex-direction: row;
            gap: 20px;

            @media (max-width: $tablet_max) {
                flex-direction: column;
            }

            .info-block-type-1 {
                @media (max-width: 1379px) {
                    &:not(:first-child) {
                        margin-left: 0;
                    }
                }
            }
        }

        @media (max-width: $tablet-max) {
            padding: 40px 54px 40px 40px;

            .image-columns {
                flex-direction: row;
                gap: 24px;
            }

            .info-block-type-1__image {
                max-width: 325px;

                img {
                    width: 325px;
                    height: 325px;
                }
            }
        }

        @media (max-width: $phone-max) {
            padding: 20px 20px 30px 20px;

            .image-columns {
                gap: 20px;
                flex-direction: column;
            }

            .info-block-type-1__image {
                img {
                    width: 258px;
                    height: 158px;
                }
            }
        }
    }

    &__nav-item-back-btn {
        display: none;

        @media (max-width: $tablet-max) {
            display: flex;
            align-items: center;
            background: $color_white;
            color: $color_grey_2;
            padding-left: 20px;
            height: 80px;

            .aroma-ico-arrow_left-long {
                font-size: 40px;
                margin: 20px 0;
                color: $color_dark_grey_2;
            }
            .aroma-header__nav-link {
                @include h6;
                letter-spacing: 0.03em;
                padding: 19px 0;
                margin-left: 62px;
                margin-right: auto;
            }
        }

        @media (max-width: $phone-max) {
            display: none;
            height: 56px;

            .aroma-ico-arrow_left-long {
                margin: 8px 0;
            }
        }
    }

    &__category-menu-left {
        display: flex;
        gap: 90px;
        margin-right: 98px;

        .categories__list {
            display: flex;
            flex-direction: column;
            gap: 10px;

            a {
                white-space: nowrap;
            }

            .category-column {
                width: fit-content;
            }
        }

        .categories__link {
            @include custom-font-inter(600, 12px, 16px);
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: $color_black;
            white-space: nowrap;

            &-sale {
                color: $color_red;
            }
        }

        @media (max-width: $tablet-max) {
            padding: 40px;
            margin-right: 0;
            border-bottom: 1px solid $color_grey_light;
        }

        @media (max-width: $phone-max) {
            padding: 0;
            border-bottom: none;

            .categories__list {
                gap: 16px;
            }
        }
    }

    &__category-menu-right {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        grid-auto-rows: minmax(auto, auto);
        grid-column-gap: 90px;
        grid-row-gap: 20px;
        width: 100%;
        overflow-y: auto;

        .category-column {
            width: fit-content;
        }

        @media (max-width: $tablet-max) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-auto-rows: minmax(auto, auto);
            grid-column-gap: 20px;
            grid-row-gap: 40px;
            padding: 46px 40px;
            overflow-y: initial;
        }

        @media (max-width: $phone-max) {
            display: flex;
            padding: 0;
            flex-direction: column;
            gap: 0;

            .category-column {
                width: 100%;

                span {
                    display: inline-block;
                    position: relative;
                    width: 100%;
                    padding: 16px 0 0;
                    // border-top: 1px solid $color_grey_light;

                    .category-column__title-arrow {
                        display: none;
                        position: absolute;
                        left: 20px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 15px;
                        height: 8px;
                        padding: 0;
                        background: url("../../images/icons/expand_collapse.svg") no-repeat center;
                        transition: transform 0.3s ease-in-out;
                        border-top: none;

                        &::after {
                            display: block;
                            content: "";
                            position: absolute;
                            left: -5px;
                            top: -10px;
                            width: 25px;
                            height: 25px;
                            background: transparent;
                        }
                    }

                    &.empty-list {
                        .category-column__title-arrow {
                            display: none;
                        }
                    }
                }

                ul {
                    margin-top: 16px;
                    padding: 0 0 0 16px;
                }
            }
        }
    }

    // Search

    &__search-btn {
        height: 18px;
        width: 18px;
        cursor: pointer;
        position: relative;
        margin-right: 10px;
        transition: 0.5s transform ease-in-out;

        &:hover {
            transform: rotate(35deg);
        }
    }

    &__search-close {
        cursor: pointer;
        transition: 0.3s transform linear;
        height: 30px;
        width: 30px;
        margin-right: 10px;
        z-index: 1;
        display: flex;
        align-items: center;
        @media (max-width: $tablet_max) {
            margin-right: 0;
        }
    }

    &__search-wrap {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        visibility: hidden;
        z-index: 4;
        width: 100%;
        height: 140px;
        background: $color_white;
        left: 0;
        top: -100%;
        transition: 0.3s top ease, 0.2s visibility linear;
        @media (max-width: $tablet-max) {
            height: 80px;
        }
        @media (max-width: $phone-max) {
            height: 68px;
        }

        &.active {
            transition: 0.3s top ease, 0s visibility linear;
            visibility: visible;
            display: flex;
            justify-content: center;
            align-items: center;
            left: 0;
            top: 0;

            &:before {
                content: "";
                position: absolute;
                display: block;
                left: -49vw;
                width: 50vw;
                height: 100%;
                background: $color_white;
            }

            &:after {
                content: "";
                position: absolute;
                display: block;
                right: -49vw;
                width: 50vw;
                height: 100%;
                background: $color_white;
            }
        }
    }

    &__search-result {
        opacity: 0;
        visibility: hidden;
        transition: 0.3s opacity linear;
        position: absolute;
        top: 140px;
        background: $color_white_smoke;
        display: flex;
        flex-direction: column;
        width: 100%;
        z-index: 5;

        &::after,
        &::before {
            content: "";
            position: absolute;
            display: block;
            top: 0;
            width: 50vw;
            height: 100%;
            background: $color_white_smoke;
        }

        &:before {
            left: -49vw;
        }

        &:after {
            right: -49vw;
        }

        @media all and (max-width: $tablet_max) {
            top: 80px;
        }
        @media all and (max-width: $phone_max) {
            top: 68px;
        }

        &.active {
            visibility: visible;
            opacity: 1;
        }

        &-content {
            max-height: 73vh;
            overflow: auto;
            padding: 0 68px 85px 68px;
            z-index: 1;
            @media all and (max-width: $tablet_max) {
                padding-left: 20px;
                padding-right: 20px;
            }
            @media all and (max-width: $phone_max) {
                padding: 10px 0 65px 0;
            }
        }

        &-mb-separator {
            @media all and (max-width: $phone_max) {
                width: 65px;
                margin-right: 20px;
            }
        }

        // &-amount {
        //   margin-left: 40px;
        //   position: relative;
        //   font-weight: 400;
        //   align-self: center;
        //   @media all and (max-width: $phone_max) {
        //     margin-left: 0;
        //   }
        // }
        &-more {
            margin-top: 30px;
            @media all and (max-width: $phone_max) {
                margin-top: 20px;
            }
        }

        // &-item {
        //   display: flex;
        //   flex-wrap: wrap;
        //   border-bottom: 1px solid $color_dark_grey;
        //   padding: 30px 0;
        // }
        // &-txt {
        //   display: flex;
        //   flex-direction: column;
        //   @media all and (max-width: $phone_max) {
        //     width: calc(100% - 65px - 20px);
        //   }
        // }
        // &-brand {
        //   @include txt__roboto;
        //   font-weight: 700;
        //   text-transform: uppercase;
        //   color: $color_grey_2;
        //   position: relative;
        //   margin-bottom: 20px;
        //   @media all and (max-width: $phone_max) {
        //     margin-bottom: 5px;
        //   }
        // }
        // &__type,
        // &__name {
        //   @include txt__cormorant;
        //   font-size: 20px;
        //   line-height: 1.3;
        // }
        &__name {
            .search-highlight {
                background: $color_gold;
            }
        }

        // &__separator {
        //   @include txt__cormorant;
        //   margin-left: 15px;
        //   margin-right: 15px;
        // }
        // &-img-wrap {
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        //   border-radius: 200px;
        //   overflow: hidden;
        //   flex: 0 0 auto;
        //   width: 65px;
        //   height: 65px;
        //   margin-right: 50px;
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        //   background: $color_white;
        //   align-self: center;
        //   img {
        //     object-fit: contain;
        //   }
        //   @media all and (max-width: $phone_max) {
        //     margin-right: 20px;
        //     width: 65px;
        //   }
        // }
        .small-product-item-info {
            padding: 30px 0;
            border-bottom: 1px solid $color_dark_grey;
            border-radius: 0;
            background: none;
            margin: 0;

            @media all and (max-width: $phone_max) {
                align-items: flex-start;
                padding: 20px 0 26px;
            }

            .search-highlight {
                background: $color_yellow;
            }

            &__brand {
                color: $color_dark_grey_2;
                margin-bottom: 20px;
                align-self: flex-start;
                @media all and (max-width: $phone_max) {
                    margin-bottom: 5px;
                }
            }

            &__desc {
                display: flex;
                flex-direction: column;
                margin-left: 25px;
                @media all and (max-width: $phone_max) {
                    margin-left: 0;
                }
            }

            &__brand {
                &:after {
                    display: none;
                }
            }

            &__amount {
                display: none;
            }
        }
    }

    &__search-overlay {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: block;
        background: rgba(56, 55, 61, 0.7);
        visibility: hidden;
        opacity: 0;
        height: 100vh;
        transition: 0.3s opacity linear;

        &.active {
            visibility: visible;
            opacity: 1;
            z-index: 3;
        }
    }

    &__search-form {
        width: 100%;
        margin: 0 32px;
        @media (max-width: $phone_max) {
            margin: 0 40px;
        }
        @media (max-width: $phone_max) {
            margin: 0 22px;
        }
    }

    &__search-input {
        padding: 0;
        border: 0;
        color: $color_black;
        width: 100%;
        @include custom-font-inter(400, 26px, 33px);

        &::placeholder {
            color: $color_grey_2;
        }

        @media (max-width: $phone_max) {
            font-size: 22px;
            line-height: normal;
        }
    }

    &__search-ico {
        display: inline-flex;
        width: 30px;
        height: 30px;
        z-index: 1;
        margin-left: 10px;
        @media (max-width: $tablet-max) {
            margin-left: 20px;
            width: 25px;
            height: 25px;
        }
        @media (max-width: $phone-max) {
            margin-left: 3px;
            width: 30px;
            height: 30px;
        }
    }

    // burger
    &__burger {
        display: none;

        @media (max-width: $tablet-max) {
            display: flex;
            justify-content: center;
            width: 24px;
        }

        @media (max-width: $phone-max) {
            position: absolute;
            left: 0;
        }
    }

    &__mobile-nav {
        display: none;
        @media (max-width: $tablet-max) {
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100vw;
            z-index: 4;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s linear;
            &.active {
                opacity: 1;
                visibility: visible;
                transition: opacity 0.3s linear;
            }
        }
    }

    &_checkout {
        display: flex;
        justify-content: center;
        align-items: center;

        .aroma-header__logo {
            padding-right: 0;
        }
    }

    // PerfectScroll

    @media (min-width: $phone-max) and (max-width: $tablet-max) {
        .ps__thumb-y {
            width: 5px;
            background: $color_grey_light;
            opacity: 1;
            max-height: 484px !important;
        }

        .ps__rail-y {
            position: fixed !important;
            top: 125px !important;
            right: 40px;
            width: 5px;
            background: $color_white;
            opacity: 1;
            max-height: 484px !important;
        }
    }
}

.aroma-header__btn-img {
    width: 100%;
    height: auto;
    object-fit: contain
}

.header-info {
    position: relative;
    align-items: center;
    width: 100%;
    font-family: $inter;
    font-size: 12px;
    font-weight: normal;
    line-height: 17px;

    @media (max-width: $phone_max) {
        margin-top: -1px;
    }

    &__item {
        padding: 1px 0;
        display: flex;
        align-items: center;
        align-content: center;
        flex-shrink: 0;
        width: 100%;
        height: auto;
        text-align: center;

        &-text {
            margin: 0 auto;
            max-width: 80%;
            letter-spacing: .05em;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            white-space: normal;
            overflow: hidden;
        }
    }

    .swiper-wrapper {
        display: flex;
        width: 100%;
    }

    .arrow-next,
    .arrow-prev {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        display: grid;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: none;
    }

    .arrow-prev {
        left: 56px;

        @media (max-width: $phone_max) {
            left: 16px;
        }

        @media (max-width: $screen__xs) {
            left: 6px;
        }
    }

    .arrow-next {
        right: 56px;

        @media (max-width: $phone_max) {
            right: 16px;
        }

        @media (max-width: $screen__xs) {
            right: 6px;
        }
    }
}
